import { Component } from "react";

import "./SnackBars.scss";

export default class SnackBars extends Component{

    constructor(props){
        super(props);

        this.message = "";
        this.typeClass = "CustomSnackBars";

        this.message = props.message;

        if(props.type){
            //default values
            switch(props.type){
                case "error":
                    this.typeClass += " error";
                    break;
                case "alert":
                    this.typeClass += " alert";
                    break;
                case "success":
                    this.typeClass += " success";
                    break;
                default:
                    break;
            }
        }

        
    }

    render(){
        return(
            <div className={this.typeClass}>
                <p>{this.message}</p>
            </div>
        );
    }
}