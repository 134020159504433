import React, { Component } from "react";
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import md5 from 'md5';

import "./Profile.scss";
import {config, apiRoutes, Logout} from  "../../../../Utils/global";

class Profile extends Component{

    constructor(props){
        super(props);
        this.state = {
            pwOriginal: null,
            pwNew1: null,
            pwNew2: null
        }


    }

    handleChangePwInput = (event, stateName) => {
        this.setState({
            [stateName]:event.target.value
        });
    }

    handleSubmitChangePassword = () => {
        let oldPwMd5 = md5(this.state.pwOriginal);
        let newPwMd5 = "";
        if(this.state.pwNew1 === this.state.pwNew2){
            newPwMd5 = md5(this.state.pwNew1);
        }else{
            return;
        }

        

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ extFunction: "changePw", oldPwMd5: oldPwMd5, newPwMd5: newPwMd5 })
        };
        fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
            .then(response => response.json().then(data => ({status: response.status, body: data})))
            .then(obj => {
                let data = obj.body;
                if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Password aggiornata',
                        showConfirmButton: false,
                        timer: 1000
                    });
                }else{
                    if(obj.status === 401){
                        Logout();
                        this.props.history.replace('/login');                                        
                    }else{
                        if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                            Swal.fire(
                                'Oh no...',
                                data.errorMessage,
                                'error'
                            );
                        }else{
                            Swal.fire(
                                'Oh no...',
                                'Qualcosa è andato storto',
                                'error'
                            );
                        }                  
                    }
                }
            });
    }

    render(){
        return(
            <div className="profile-subpage">
                <div className="title">Gestione Profilo</div>
                <div className="list-profile-manage">
                    <div className="manage-sections pwd-section">
                        <div className="manage-title not-selectable">Password</div>
                        <div className="manage-content">
                            <TextField
                                value={this.state.pwOriginal}
                                onChange={(e)=>this.handleChangePwInput(e, 'pwOriginal')}
                                className="manage-content-inputField"
                                inputProps={{autoComplete: 'chrome-off'}}
                                id="outlined-basic"
                                type="password"
                                label="Password attuale"
                                variant="outlined" />
                            <TextField
                                value={this.state.pwNew1}
                                onChange={(e)=>this.handleChangePwInput(e, 'pwNew1')}
                                className="manage-content-inputField"
                                inputProps={{autoComplete: 'chrome-off'}}
                                error={(this.state.pwNew2 !== this.state.pwNew1)}
                                id="outlined-basic"
                                type="password"
                                label="Nuova Password"
                                variant="outlined" />
                            <TextField
                                value={this.state.pwNew2}
                                onChange={(e)=>this.handleChangePwInput(e, 'pwNew2')}
                                className="manage-content-inputField"
                                inputProps={{autoComplete: 'chrome-off'}}
                                error={(this.state.pwNew2 !== this.state.pwNew1)}
                                id="outlined-basic"
                                type="password"
                                label="Conferma Password"
                                variant="outlined" />
                            <Button
                                disabled={
                                    (   this.state.pwOriginal === null
                                    ||  (this.state.pwOriginal !== null && this.state.pwOriginal.trim() === "") 
                                    ||  (this.state.pwNew1 === null || this.state.pwNew2 === null)
                                    ||  (this.state.pwNew1 !== null && this.state.pwNew1.trim() === "")
                                    ||  (this.state.pwNew2 !== null && this.state.pwNew2.trim() === "")
                                    ||  (this.state.pwNew1 !== null && this.state.pwNew2 !== null && (this.state.pwNew1.trim() !== this.state.pwNew2.trim())))
                                }
                                className="manage-content-Button"
                                variant="contained"
                                color="primary"
                                onClick={()=>this.handleSubmitChangePassword()}>
                                Cambia Password
                            </Button>
                        </div>
                    </div>
                    <div className="manage-sections email-section">
                        <div className="manage-title not-selectable">Email</div>
                        <div className="manage-content">

                        </div>
                    </div>
                    <div className="manage-sections preferences-section">
                        <div className="manage-title not-selectable">Preferenze</div>
                        <div className="manage-content">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Profile);