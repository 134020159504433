import { Component } from "react";
import ReactLoading from 'react-loading';
import { DataGrid, itIT } from '@material-ui/data-grid';
import Swal from 'sweetalert2';

import "./ImpiantiTable.scss";
import {config, Logout, apiRoutes} from  "../../../../../Utils/global";
import SnackBars from "../../../../../components/SnackBars/SnackBars";
import {checkLocalDBOrGetIt} from "../../../../Login/LoginServices";
import DetailImpianto from "../../../../../components/DetailImpianto/DetailImpianto";

function createDataRow(arrayIndex, id, indirizzo, comune, tipologia, formato, illuminazione) {
    return { arrayIndex, id, indirizzo, comune, tipologia, formato, illuminazione };
}

const columns = [
    {   
        field: 'id',
        headerName: 'ID',
        width: 90,
        type: 'number',
        align: 'left',
        headerAlign: 'left'
    },
    {
        field: 'indirizzo',
        headerName: 'Indirizzo',
        width: 400
    },
    {
        field: 'comune',
        headerName: 'Comune',
        width: 150
    },
    {
        field: 'tipologia',
        headerName: 'Tipologia',
        width: 200
    },
    {
        field: 'formato',
        headerName: 'Formato',
        width: 150
    },
    {
        field: 'illuminazione',
        headerName: 'Illuminazione',
        width: 180
    }
];

export default class ImpiantiTable extends Component{

    constructor(props){
        super(props);
        this.state = {
            isSubpageLoading: true,
            ErrorSnack: null,
            impiantiRows: [],
            detailDialog: null
        }
        this.impiantiOriginal = [];
        this.selectedRowsChanged = this.props.selectedRowsChanged;
    }

    async checkLocalDB() {
        let returnedMessage = await checkLocalDBOrGetIt();
        return returnedMessage;
    }

    deleteLocallyFromTable = (idRowToDelete) => {
        if(idRowToDelete !== null && idRowToDelete !== [] && idRowToDelete.length > 0){
            var filteredRows = this.impiantiOriginal.filter(
                function(e) {
                  return this.indexOf(e.id) < 0;
                },
                idRowToDelete
            );
            let newImpiantiRows = [];
            for(let i=0; i<filteredRows.length; i++){
                let thisImpiantoTemp = filteredRows[i];
                newImpiantiRows.push(
                    createDataRow(
                        i,
                        thisImpiantoTemp.id, 
                        thisImpiantoTemp.indirizzo, 
                        thisImpiantoTemp.comune,
                        thisImpiantoTemp.tipologia,
                        thisImpiantoTemp.formato,
                        thisImpiantoTemp.tipoilluminazione
                    )
                );
            }
            this.impiantiOriginal = newImpiantiRows;
            this.setState({
                isSubpageLoading: false,
                impiantiRows: newImpiantiRows
            })
        }
    }

    loadResultsRow = () =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ init: "impianti" })
        };
        fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
            .then(response => response.json().then(data => ({status: response.status, body: data})))
            .then(obj => {
                let data = obj.body;
                if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                    if(data.responseMessage.impianti){
                        let impiantiFromServer = [];
                        for(let i=0; i<data.responseMessage.impianti.length; i++){
                            let thisImpiantoTemp = data.responseMessage.impianti[i];
                            impiantiFromServer.push(
                                createDataRow(
                                    i,
                                    thisImpiantoTemp.id, 
                                    thisImpiantoTemp.indirizzo, 
                                    thisImpiantoTemp.comune,
                                    thisImpiantoTemp.tipologia,
                                    thisImpiantoTemp.formato,
                                    thisImpiantoTemp.tipoilluminazione
                                )
                            );
                        }
                        this.impiantiOriginal = data.responseMessage.impianti;

                        //Check integrity of localDB
                        this.checkLocalDB().then((isOk) => {
                            if(isOk === true){
                                this.setState({
                                    isSubpageLoading: false,
                                    impiantiRows: impiantiFromServer
                                });
                            }else{
                                Swal.fire(
                                    'Oh no...',
                                    "C'è stato un errore, riprova più tardi",
                                    'error'
                                );
                            }
                        });

                        
                    }else{
                        this.setState({ErrorSnack: <SnackBars type="error" message={"Empty Response"}/>});  
                    }
                }else{
                    if(obj.status === 401){
                        Logout();
                        this.props.history.replace('/login');                                        
                    }else{
                        this.setState({ErrorSnack: <SnackBars type="error" message={"Server Error"}/>});                    
                    }
                }
            });
    }

    handleDoubleClickRow = (arrayIndex) =>{
        if(arrayIndex === "*new"){
            this.setState({
                detailDialog: 
                    <DetailImpianto 
                        impianto={"*new"}
                        closeDialogFunction={this.closeDialogFunction}
                    />
            });
        }else{
            this.setState({
                detailDialog: 
                    <DetailImpianto 
                        impianto={this.impiantiOriginal[arrayIndex]}
                        closeDialogFunction={this.closeDialogFunction}
                    />
            });
        }
    }

    closeDialogFunction = (reloadResults) => {
        this.setState({
            detailDialog: null
        });
        if(typeof reloadResults !== undefined && reloadResults === true){
            this.impiantiOriginal = [];
            this.setState({
                isSubpageLoading: true,
                impiantiRows: null
            }, () =>{
                this.loadResultsRow();
            });            
        }
    }

    componentDidMount = () => {
        this.loadResultsRow();
    }

    render(){
        return(

            <div className="impiantiTableContainer">
                { this.state.isSubpageLoading ? (
                    <div>
                        <div className="layoutSubpageLoadingAnimation"> 
                            <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                        </div>
                        {this.state.ErrorSnack}
                    </div>
                ) : (

                    <div className="impiantiTable">
                        <div style={{ height: '100%', width: '100%' }}>
                            <DataGrid
                                className="dataGridImpianti"
                                localeText={itIT.props.MuiDataGrid.localeText}
                                rows={this.state.impiantiRows}
                                columns={columns}
                                /*pageSize={11}*/
                                checkboxSelection
                                disableSelectionOnClick
                                onCellDoubleClick={(params, event) => {
                                    if (!event.ctrlKey) {
                                        this.handleDoubleClickRow(params.row.arrayIndex);
                                    }
                                }}
                                getCellClassName={(params) => {
                                    return 'customCell';
                                }}
                                getRowClassName={(params) => {
                                    return 'customRow';
                                }}
                                onSelectionModelChange={(e) => {
                                    this.selectedRowsChanged(e);
                                }}
                            />
                        </div>
                        {this.state.detailDialog}
                    </div>
                )}
            </div>
        );
    }
}