import { Component } from "react";
import { withRouter } from "react-router";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//import ShareIcon from '@material-ui/icons/Share';
//import IconButton from '@material-ui/core/IconButton';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import "./CardImpianto.scss";
import {config} from  "../../../../Utils/global";
import DetailImpianto from "../../../../components/DetailImpianto/DetailImpianto";

class CardImpianto extends Component{

    constructor(props){
        super(props);
        this.impiantoMapCallback = this.props.impiantoMapCallback;
        this.selectedCardsForCustomDownload = this.props.selectedCardsForCustomDownload;
        this.reloadResultsCallback = this.props.reloadResultsCallback;
        this.images = this.props.impianto.images;
        this.state = {
            detailDialog: null,
            impianto: this.props.impianto
        }
    }

    closeDialogFunction = (reloadResults) => {
        this.setState({
            detailDialog: null
        });
        if(typeof reloadResults !== undefined && reloadResults === true){
            this.reloadResultsCallback();
        }
    }

    detailButtonHandler = () => {
        this.setState({
            detailDialog: 
                <DetailImpianto 
                    impianto={this.state.impianto}
                    closeDialogFunction={this.closeDialogFunction}
                />
        })
    }

    render(){

        var cardImageSection;
        if(this.images!=null){
            let imagesN = this.images.split(",");
            let previewImageSrc = config.impiantiImagesPath+"/impianto"+this.state.impianto.id+"_"+imagesN[0];
            cardImageSection =
                <LazyLoadImage
                    alt="Anteprima Impianto"
                    height={160}
                    src={previewImageSrc}
                    className="cardImpiantoImage"
                    />
        }else{
            cardImageSection =
                <LazyLoadImage
                    alt="Anteprima Impianto"
                    height={160}
                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1280px-Image_created_with_a_mobile_phone.png"}
                    className="cardImpiantoImage"
                    />
        }

        return(
            <Card className={"CardImpianto"} id={'cardimpianto-'+this.state.impianto.id} data-indirizzo={this.state.impianto.indirizzo}>
                <CardActionArea onClick={()=>{this.impiantoMapCallback(this.state.impianto.id);}}>
                    
                    { cardImageSection }                    
                    
                    <CardContent>
                    <Typography gutterBottom className="title" variant="h5" component="h2">
                        {this.state.impianto.indirizzo}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                        <p className="subtitle-text"><b>{this.state.impianto.comune} ({this.state.impianto.siglaprovincia}), {this.state.impianto.regione}</b></p>
                        <span className="details-text">
                            <p><b>Tipologia</b>: {this.state.impianto.tipologia}</p>
                            <p><b>Formato</b>: {this.state.impianto.formato}</p>
                            <p><b>Cimasa</b>: {this.state.impianto.cimasa}</p>
                            <p><b>Illuminazione</b>: {this.state.impianto.tipoilluminazione}</p>
                            <p><b>Concessionario</b>: {this.state.impianto.concessionario}</p>
                        </span>
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {/*<IconButton size="small" color="primary">
                        <ShareIcon/>
                    </IconButton>*/}
                    <Button size="small" color="primary" onClick={this.detailButtonHandler}>
                        Dettaglio
                    </Button>
                    <div className="checkBoxLayout"><Checkbox color="primary" name="checkboxCardsImpianti" className="checkBox" icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} onChange={(e)=> this.selectedCardsForCustomDownload(this.state.impianto.id, e)}/></div>
                </CardActions>
                {this.state.detailDialog}
            </Card>
        );
    }
}

export default withRouter(CardImpianto);