import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
//import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { VariableSizeList } from 'react-window';
import ListSubheader from '@material-ui/core/ListSubheader';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./AutocompleteSelect.scss";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
        },
    });
}

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
        return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
        <OuterElementContext.Provider value={other}>
            <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
            >
            {renderRow}
            </VariableSizeList>
        </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};


export default class AutocompleteSelect extends React.Component{

    constructor(props){
        super(props);
        this.identifier = this.props.identifier;
        this.filterOnChange = this.props.filterOnChange;
        this.onChangeFunction = this.props.onChangeFunction;
        this.colorChips = this.props.colorChips;
        this.hugeOptions = this.props.hugeOptions;
        this.groupBy = (this.props.groupBy !== undefined)? this.props.groupBy : true;
        this.state = {
            options: this.props.options,
            values: (this.props.selectedValues !== undefined && this.props.selectedValues !== null && this.props.selectedValues.length>0)? this.props.selectedValues : []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.options !== this.props.options){
            this.setState({
                options: this.props.options
            });
        }
        if(prevProps.selectedValues !== this.props.selectedValues){
            this.setState({
                values: this.props.selectedValues,
                selectedValues: this.props.selectedValues
            });            
        }
    }

    render(){

        if(this.hugeOptions != null && this.hugeOptions === true){

            //Versione ottimizzata per tante options
            return(
                <Autocomplete
                    multiple
                    id={'select-'+this.identifier}
                    value={this.state.values}
                    options={this.state.options}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "chrome-off";
                        return(
                            <TextField 
                                {...params}
                                inputProps={inputProps}
                                variant="outlined" 
                            /> 
                        );
                    }}
                    filterSelectedOptions={this.filterOnChange}
                    groupBy={(option) => option.nome[0].toUpperCase()}

                    disableListWrap
                    
                    ListboxComponent={ListboxComponent}
                    renderGroup={renderGroup}
                    
                    
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip color={this.colorChips} label={option.nome} {...getTagProps({ index })} />
                        ))
                    }
                    onChange={(event, newValue) => {
                        this.setState({values: newValue});
                        this.onChangeFunction(newValue,this.identifier);
                    }}
                    //renderOption={(option) => <Typography noWrap>{option}</Typography>}
                />
            );

        }else{

            //Autocomplete Tradizionale
            return(
                <Autocomplete
                    multiple
                    id={'select-'+this.identifier}
                    value={this.state.values}
                    options={this.state.options}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "chrome-off";
                        return(
                            <TextField 
                                {...params}
                                inputProps={inputProps}
                                variant="outlined" 
                            /> 
                        );
                    }}
                    filterSelectedOptions={this.filterOnChange}
                    groupBy={(this.groupBy)?(option) => option.nome[0].toUpperCase() : undefined}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip color={this.colorChips} label={option.nome} {...getTagProps({ index })} />
                        ))
                    }
                    onChange={(event, newValue) => {
                        this.setState({values: newValue});
                        this.onChangeFunction(newValue,this.identifier);
                    }}
                    //renderOption={(option) => <Typography noWrap>{option}</Typography>}
                />
            );

        }

        
    }
}