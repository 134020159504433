import React, { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Swal from "sweetalert2";

import "./Management.scss";
import {config, Logout, apiRoutes} from  "../../../../Utils/global";
import ImpiantiTable from "./CustomComponents/ImpiantiTable";

export default class Management extends Component{

    constructor(props){
        super(props);
        this.state = {
            btnDeleteDisabled: true
        }
        this.selectedRowsIds = [];
        this.tableImpianti = React.createRef();
    }
    
    selectedRowsChanged = (selectedIds) => {
        this.selectedRowsIds = selectedIds;
        if(selectedIds !== null && selectedIds !== [] && selectedIds.length > 0){
            this.setState({
                btnDeleteDisabled: false
            });
        }else{
            this.setState({
                btnDeleteDisabled: true
            });
        }
    }

    tableNewRow = () => {
        this.tableImpianti.current.handleDoubleClickRow("*new");
    }

    tableRowsDelete = () => {
        let confirmText = 'Vuoi davvero eliminare l\'elemento selezionato?';
        if(this.selectedRowsIds.length > 1){
            confirmText = 'Vuoi davvero eliminare i '+this.selectedRowsIds.length+' elementi selezionati?';
        }

        let htmlBody = '<b>Verranno eliminati:</b>';
        let tempImpiantiArray = this.tableImpianti.current.impiantiOriginal;
        let impiantiWillBeDeletedArray = tempImpiantiArray.filter(
            function(e) {
              return this.indexOf(e.id) > -1;
            },
            this.selectedRowsIds
        );
        for(let i=0; i<impiantiWillBeDeletedArray.length; i++){
            htmlBody += '<br/>'+impiantiWillBeDeletedArray[i].indirizzo;
        }
         
        Swal.fire({
            title: confirmText,
            showCancelButton: true,
            confirmButtonText: "Elimina",
            confirmButtonColor: "#ea5455",
            cancelButtonText: "Annulla",
            html: htmlBody,
        }).then((result) => {
            if (result.isConfirmed) {
                
                this.tableImpianti.current.setState({
                    isSubpageLoading: true
                }, () => {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ delete: this.selectedRowsIds })
                    };
                    fetch(config.SERVER_ADDR+apiRoutes.manageImpianti, requestOptions)
                        .then(response => response.json().then(data => ({status: response.status, body: data})))
                        .then(obj => {
                            let data = obj.body;
                            if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                                this.tableImpianti.current.deleteLocallyFromTable(this.selectedRowsIds);
                            }else{
                                if(obj.status === 401){
                                    Logout();
                                    this.props.history.replace('/login');                                        
                                }else{
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Qualcosa è andato storto!'
                                    });
                                    console.error(data);
                                }
                            }
                            this.tableImpianti.current.setState({
                                isSubpageLoading: false
                            });
                        });
                });
                                
            }
        });
    }

    render(){
        return(
            <div className="management-subpage">
                <div className="title-section">
                    <span className="title">Gestione Impianti</span>
                    <span className="tableActions">
                        <IconButton style={{backgroundColor:'transparent'}} aria-label="new" onClick={()=>{this.tableNewRow()}}>
                            <AddCircleIcon fontSize="small"/>
                        </IconButton>
                        <IconButton style={{backgroundColor:'transparent'}} disabled={this.state.btnDeleteDisabled} aria-label="delete" onClick={()=>{this.tableRowsDelete()}}>
                            <DeleteIcon fontSize="small"/>
                        </IconButton>
                    </span>
                </div>
                <ImpiantiTable ref={this.tableImpianti} selectedRowsChanged={this.selectedRowsChanged}/>
            </div>
        );
    }
}