/* Libraries */
import React, {Component} from "react";
import { withRouter } from "react-router";
import ReactLoading from 'react-loading';


/* Components/Utils/Styles */
import {capitalize} from "../../Utils/tools.js";
import "./Home.scss";
import {isLogged} from '../Login/LoginServices';
import {config} from  "../../Utils/global";
import Header from "./CustomComponents/Header/Header";
import PreviewImage from "../../assets/images/preview.png"



class Home extends Component{

  constructor(props) {
    super(props)
    this.searchInput = React.createRef();
    this.state = {
      searchTerm:'',
      isLoadin: true
    }
  }

  search = (query)=>{
    if(this.searchInput.current.value.trim()!=="" || query==="query"){
      this.props.history.push('/search/'+capitalize(this.searchInput.current.value));
    }
  }

  keyPressed = (e)=>{
    if (e.key === 'Enter') {
      this.search();
    }
  }

  render(){
    return(
        <div className="home-page">
          { this.state.isLoading ? (
              <div className="layoutLoadingAnimation"> 
                  <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
              </div>
          ) : (
            <div>
              <Header isCorrectlyLogged={isLogged()}/>
              <div className="section1-homepage">
                <div className="sectionSearch">
                  <div className="leftSection">
                    <p className="title not-selectable">Cerca gli impianti adatti<br/>per la tua pubblicità.</p>
                    <div className="searchContainer">
                      <div className="inputContainer">
                        <i className="searchIcon"/>
                        <input type="text" className="searchTool" placeholder="Es. Milano" value={this.state.searchTerm} onChange={(e)=>this.setState({searchTerm: e.target.value})} onKeyDown={this.keyPressed} ref={this.searchInput}/>
                      </div>
                      <div className="searchButton not-selectable" onClick={this.search}>Cerca</div>
                    </div>
                  </div>
                  <div className="mapsimage"/>
                </div>
              </div>
              <div className="section2-homepage">
                <div className="section2-content">
                  <img className="preview not-selectable" alt="preview" src={PreviewImage}/>
                </div>
              </div>
              <div className="footer-homepage">
                <div className="footer-content">
                  <span className="footer-text">© Portaloutdoor.net, All rights reserved.</span>
                </div>
              </div>
            </div>
          )}
        </div>
    );
  }
}


export default withRouter(Home);
