import { Component } from "react";
import { withRouter } from "react-router";
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReactLoading from 'react-loading';
import red from '@material-ui/core/colors/red';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';

import "./Settings.scss";
import {config, apiRoutes, Logout} from  "../../Utils/global";
import Profile from "./subpages/Profile/Profile";

class Settings extends Component{

    constructor(props){
        super(props);

        if(!this.props.match.params.sub){
            this.props.history.replace('/settings/home');
        }

        this.settingsHomeSubpage = <div></div>;
        this.settingsProfileSubpage = <Profile/>;

        let contentDynamicToShow = <div></div>;
        if(this.props.match.params.sub){
            if(this.props.match.params.sub === "profile"){
                contentDynamicToShow = this.settingsProfileSubpage;
            }else if(this.props.match.params.sub === "home"){
                contentDynamicToShow = this.settingsHomeSubpage;
            }
        }        

        this.state = {
            activeSubpage: this.props.match.params.sub,
            isLoading: true,
            dynamicContent: contentDynamicToShow,
            accountButtonMenu: null
        }        
    }

    handleClickProfileIcon = (event) =>{
        this.setState({
            accountButtonMenu: event.currentTarget
        })
    }

    handleClickHeaderActions = (action) =>{
        this.setState({
            accountButtonMenu: null
        });
        switch(action){
            case "logout":
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ extFunction: "logout" })
                };
                fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                    .then(() => {
                        Logout();
                        this.props.history.replace('/');
                    });
                break;
            case "profile":
                this.props.history.replace('/settings/profile');
                break;
            case "home":
                this.props.history.replace('/settings/home');
                break;
            case "dashboard":
                this.props.history.push('/dashboard');
                break;
            default:
                break;
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.sub !== this.props.match.params.sub){
            let contentDynamicToShow = <div></div>;
            if(this.props.match.params.sub){
                if(this.props.match.params.sub === "profile"){
                    contentDynamicToShow = this.settingsProfileSubpage;
                }else if(this.props.match.params.sub === "home"){
                    contentDynamicToShow = this.settingsHomeSubpage;
                }
            }     
            this.setState({
                activeSubpage: this.props.match.params.sub,
                dynamicContent: contentDynamicToShow
            });
        }
    }

    handleSubpageChange = (subpage) =>{
        if(subpage === "profile" || subpage === "home"){
            this.props.history.replace('/settings/'+subpage);
        }
    }


    componentDidMount(){
        this.setState({
            isLoading: false
        });
    }


    render(){
        return(
            <div>
                { this.state.isLoading ? (
                    <div className="layoutLoadingAnimation"> 
                        <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                    </div>
                ) : (
                    <div className="settings-page">
                        <div className="settings-header">
                            <div className="title"><img title={"Home"} className="iconToHome" alt="" src={process.env.PUBLIC_URL + '/logo512.png'} onClick={()=>{this.props.history.push('/');}}></img>Impostazioni</div>
                            <div className="actions">
                                <Avatar title={"Profilo"} onClick={this.handleClickProfileIcon} className="user">
                                    <PersonIcon/>
                                </Avatar>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.accountButtonMenu}
                                    keepMounted
                                    open={Boolean(this.state.accountButtonMenu)}
                                    onClose={this.handleClickHeaderActions}
                                >
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('dashboard')}}>
                                        <ListItemIcon>
                                            <DashboardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Dashboard
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('profile')}}>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Profilo
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('home')}}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Impostazioni
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('logout')}}>
                                        <ListItemIcon>
                                            <ExitToAppIcon  style={{ color: red[500] }} fontSize="small"/>
                                        </ListItemIcon>
                                        <Typography variant="inherit" style={{ color: red[500] }} noWrap>
                                            Logout
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div className="content">
                            <div className="menu">
                                <div className={(this.state.activeSubpage === 'home')?"menu-item selected":"menu-item"} ref={this.menuMaps} onClick={()=>{this.handleSubpageChange('home');}}>
                                    <SettingsIcon fontSize="large"/>
                                    <p>Impostazioni</p>
                                </div>
                                <div className={(this.state.activeSubpage === 'profile')?"menu-item selected":"menu-item"} ref={this.menuMaps} onClick={()=>{this.handleSubpageChange('profile');}}>
                                    <PersonIcon fontSize="large"/>
                                    <p>Profilo</p>
                                </div>
                            </div>
                            <div className="dynamic-content">
                                {this.state.dynamicContent}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Settings);