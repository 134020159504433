import {config, apiCookies, Logout, apiRoutes} from  "../../Utils/global";
import Cookies from 'js-cookie';

export function isLogged(){
    if(Cookies.get(apiCookies.apiToken)){
        return true;
    }else{
        return false;
    }
}

export function checkLocalDBOrGetIt(){
    return new Promise(resolve => {
        var dbLocalToCheck = ['comuni','concessionari','regioni','formati','tipiilluminazione','province','tipologie','categorie'];
        var dbPartToReloadFromServer = [];
        var contCheckAll = 0;
        for(let i=0; i<dbLocalToCheck.length; i++){
            if(localStorage.getItem(dbLocalToCheck[i])){
                contCheckAll++;
            }else{
                dbPartToReloadFromServer.push(dbLocalToCheck[i]);
            }
        }
        
        if(contCheckAll === dbLocalToCheck.length){
            resolve(true);
        }else{

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ init: dbPartToReloadFromServer.join(',') })
            };
            fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
            .then(response => response.json().then(data => ({status: response.status, body: data})))
            .then(obj => {
                let data = obj.body;
                if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){

                    if(data.responseMessage.regioni){
                        localStorage.setItem('regioni', JSON.stringify(data.responseMessage.regioni));
                    }
                    if(data.responseMessage.province){
                        localStorage.setItem('province', JSON.stringify(data.responseMessage.province));
                    }
                    if(data.responseMessage.comuni){
                        localStorage.setItem('comuni', JSON.stringify(data.responseMessage.comuni));
                    }
                    if(data.responseMessage.tipologie){
                        localStorage.setItem('tipologie', JSON.stringify(data.responseMessage.tipologie));
                    }
                    if(data.responseMessage.formati){
                        localStorage.setItem('formati', JSON.stringify(data.responseMessage.formati));
                    }
                    if(data.responseMessage.tipiilluminazione){
                        localStorage.setItem('tipiilluminazione', JSON.stringify(data.responseMessage.tipiilluminazione));
                    }
                    if(data.responseMessage.concessionari){
                        localStorage.setItem('concessionari', JSON.stringify(data.responseMessage.concessionari));
                    }
                    if(data.responseMessage.categorie){
                        localStorage.setItem('categorie', JSON.stringify(data.responseMessage.categorie));
                    }
                    
                    resolve(true);

                }else{
                    if(obj.status === 401){
                        Logout();
                        this.props.history.replace('/login');                                        
                    }else{
                        resolve(false);             
                    }
                }
            });
        }
    });
}