import React, { Component } from "react";
import { withRouter } from "react-router";
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import MapIcon from '@material-ui/icons/Map';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomIcon from '@material-ui/icons/Room';
import ReactLoading from 'react-loading';
import red from '@material-ui/core/colors/red';
import SettingsIcon from '@material-ui/icons/Settings';

import "./Dashboard.scss";
import MapsSubpage from "./subpages/MapsSubpage/MapsSubpage";
import Managment from "./subpages/Management/Management";
import {isLogged} from '../Login/LoginServices';
import {config, apiRoutes, Logout} from  "../../Utils/global";

class Dashboard extends Component{

    constructor(props){
        super(props);

        //Menu buttons
        this.menuMaps = React.createRef();
        this.menuManagement = React.createRef();

        //Subpages
        this.appsPage = null;
        this.managmentPage = null;

        this.state = {
            isLoading: true,
            dynamicContent: null,
            accountButtonMenu: null,
            favoritesMaps: []
        }
    }
    
    componentDidMount(){
        
        document.title = config.defaultTitlePage;

        if(isLogged()){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ extFunction: "favoritesMaps" })
            };
            fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    var favoritesMapsFromServer = [];
                    if(data.messageType!=null && data.messageType === "response" && data.responseMessage.favoritesmaps != null){
                        if(data.responseMessage.favoritesmaps.length!=null && data.responseMessage.favoritesmaps.length > 0){
                            favoritesMapsFromServer = data.responseMessage.favoritesmaps;
                        }
                    }else{
                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }
                    }
                    
                    this.appsPage = <MapsSubpage favoritesMaps={favoritesMapsFromServer}/>;
                    this.managmentPage = <Managment/>;

                    this.setState({ 
                        isLoading: false,
                        dynamicContent: this.appsPage,
                        favoritesMaps: favoritesMapsFromServer
                    });          
                });
        }else{
            this.props.history.replace('/login');
            this.setState({ isLoading: false });
        }
        
    }

    handleSubpageChange = (subpage) =>{
        this.menuMaps.current.classList.remove("selected");
        this.menuManagement.current.classList.remove("selected");
        switch(subpage){
            case "maps":
                this.menuMaps.current.classList.add("selected");
                this.setState({dynamicContent: this.appsPage});
                break;
            case "management":
                this.menuManagement.current.classList.add("selected");
                this.setState({dynamicContent: this.managmentPage});
                break;
            default:
                break;
        }
    }

    handleClickProfile = (event) =>{
        this.setState({
            accountButtonMenu: event.currentTarget
        })
    }

    handleClickHeaderActions = (action) =>{
        this.setState({
            accountButtonMenu: null
        });
        switch(action){
            case "logout":

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ extFunction: "logout" })
                };
                fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                    .then(() => {
                        Logout();
                        this.props.history.replace('/');
                    });
                
                break;
            case "profile":
                this.props.history.push('/settings/profile');
                break;
            case "settings":
                this.props.history.push('/settings/home');
                break;
            default:
                break;
        }
    }
    
    render(){
        return(
            <div>
                { this.state.isLoading ? (
                    <div className="layoutLoadingAnimation"> 
                        <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                    </div>
                ) : (
                    <div className="dashboard-page">
                        <div className="dashboard-header">
                            <div className="title"><img title={"Home"} className="iconToHome" alt="" src={process.env.PUBLIC_URL + '/logo512.png'} onClick={()=>{this.props.history.push('/');}}></img>Dashboard</div>
                            <div className="actions">
                                <Avatar title={"Profilo"} onClick={this.handleClickProfile} className="user">
                                    <PersonIcon/>
                                </Avatar>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.accountButtonMenu}
                                    keepMounted
                                    open={Boolean(this.state.accountButtonMenu)}
                                    onClose={this.handleClickHeaderActions}
                                >
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('profile')}}>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Profilo
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('settings')}}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Impostazioni
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={()=>{this.handleClickHeaderActions('logout')}}>
                                        <ListItemIcon>
                                            <ExitToAppIcon  style={{ color: red[500] }} fontSize="small"/>
                                        </ListItemIcon>
                                        <Typography variant="inherit" style={{ color: red[500] }} noWrap>
                                            Logout
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div className="content">
                            <div className="menu">
                                <div className="menu-item selected" ref={this.menuMaps} onClick={()=>{this.handleSubpageChange('maps');}}>
                                    <MapIcon fontSize="large"/>
                                    <p>Mappe</p>
                                </div>
                                <div className="menu-item" ref={this.menuManagement} onClick={()=>{this.handleSubpageChange('management');}}>
                                    <RoomIcon fontSize="large"/>
                                    <p>Gestione</p>
                                </div>
                            </div>
                            <div className="dynamic-content">
                                {this.state.dynamicContent}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Dashboard);