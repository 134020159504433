import React from "react";
import { withRouter } from "react-router";
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactLoading from 'react-loading';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TextField from '@material-ui/core/TextField';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Swal from 'sweetalert2';
import DashboardIcon from '@material-ui/icons/Dashboard';

import "./MapPage.scss";
import {config, apiRoutes, Logout} from  "../../Utils/global";
import AutocompleteSelect from "../../components/AutocompleteSelect/AutocompleteSelect";
import closeIconleftMenu from "../../assets/icons/arrow_left_1x.png";
import {jsonToURI, uriToJSON} from "../../Utils/tools.js";
import SnackBars from "../../components/SnackBars/SnackBars";
import Map from "./Map/Map";
import CardImpianto from "./CustomComponents/CardImpianto/CardImpianto";

const StyledBadge = withStyles(() => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid white`,
        padding: '0 4px',
    },
}))(Badge);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MapPage extends React.Component{

    constructor(props){
        super(props);

        this.mappa = React.createRef();

        this.leftMenu = React.createRef();
        this.leftMenuToggleIcon = React.createRef();
        this.mapSection = React.createRef();
        this.filtersMenuSection = React.createRef();
        this.resultsMenuSection = React.createRef();
        this.handleToggleLeftMenu = this.handleToggleLeftMenu.bind(this);

        //Dataset Regioni, Province, Comuni
        this.allRegioniFromServer = null;
        this.allProvinceFromServer = null;
        this.allComuniFromServer = null;

        //Dataset Impianti e tutti gli altri filtri
        this.allImpiantiFromServer = null;
        this.allTipologieFromServer = null;
        this.allFormatiFromServer = null;
        this.allTipiilluminazioneFromServer = null;
        this.allConcessionariFromServer = null;
        this.allCategorieFromServer = null;

        //Elementi selezionati (servono per le select che hanno una relazione regione-provincia-comune)
        this.selectedItemsRegioni = [];
        this.selectedItemsProvince = [];
        this.selectedItemsComuni = [];

        //ID degli elementi selezionati
        this.selectedIDRegioni = [];
        this.selectedIDProvince = [];
        this.selectedIDComuni = [];
        //ID elementi selezionati filtri senza relazioni
        this.selectedIDTipologie = [];
        this.selectedIDFormati = [];
        this.selectedIDTipiilluminazione = [];
        this.selectedIDConcessionari = [];
        this.selectedIDCategorie = [];

        //Parametri nell'URL
        this.s = (new URLSearchParams(this.props.location.search)).get('s') || null;

        this.getMarkersAfterMapLoad = false;
        this.mapLoaded = false;

        this.currentUrlSearchParameter = '';

        this.state = {
            //Item passati al render delle select
            regioniForSelect:null,
            provinceForSelect:null,
            comuniForSelect:null,
            tipologieForSelect: null,
            formatiForSelect: null,
            tipiilluminazioneForSelect: null,
            concessionariForSelect: null,
            categorieForSelect: null,

            //Marker passati al render della mappa
            markersForMap: [],

            //Item selezionati passati al render delle select (usati principalemente per settare i parametri arrivati da URL)
            selectedRegioni: [],
            selectedProvince: [],
            selectedComuni: [],
            selectedTipologie: [],
            selectedFormati: [],
            selectedTipiilluminazione: [],
            selectedConcessionari: [],
            selectedCategorie: [],

            //Numero badge risultati
            resultsNumber: 0,

            //Variabile di controllo menu filtri/risultati
            menuSection: 0,
            resultsPaneSectionDisabled: false,
            resultsPaneContent: null,

            markerToFocusFromDetail: null,

            ErrorSnack: null,
            gettingResults: false,
            isLoading:true,

            selectedImpiantiForCustomDownload: [],

            messageSnackOpen: false,
            messageSnackMessage: '',
            messageSnackType: 'success',
        
            inputResultsSearch: ''
        }
    }

    componentDidMount(){
        document.title = "Portaloutdoor - Map";
        //fetch all dataset
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ init: "regioni,province,comuni,tipologie,formati,tipiilluminazione,concessionari,categorie" })
        };
        fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
            .then(response => response.json().then(data => ({status: response.status, body: data})))
            .then(obj => {
                let data = obj.body;
                if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                    if(data.responseMessage.regioni 
                        && data.responseMessage.province 
                        && data.responseMessage.comuni 
                        && data.responseMessage.tipologie 
                        && data.responseMessage.formati 
                        && data.responseMessage.tipiilluminazione
                        && data.responseMessage.concessionari
                        && data.responseMessage.categorie){

                        //Salvo i dati originali in LocalStorage per futuri utilizzi
                        localStorage.setItem('regioni', JSON.stringify(data.responseMessage.regioni));
                        localStorage.setItem('province', JSON.stringify(data.responseMessage.province));
                        localStorage.setItem('comuni', JSON.stringify(data.responseMessage.comuni));
                        localStorage.setItem('tipologie', JSON.stringify(data.responseMessage.tipologie));
                        localStorage.setItem('formati', JSON.stringify(data.responseMessage.formati));
                        localStorage.setItem('tipiilluminazione', JSON.stringify(data.responseMessage.tipiilluminazione));
                        localStorage.setItem('concessionari', JSON.stringify(data.responseMessage.concessionari));
                        localStorage.setItem('categorie', JSON.stringify(data.responseMessage.categorie));

                        //Salvo i dati originali arrivati Fitri e tutti gli impianti
                        this.allRegioniFromServer = data.responseMessage.regioni;
                        this.allProvinceFromServer = data.responseMessage.province;
                        this.allComuniFromServer = data.responseMessage.comuni;

                        this.allImpiantiFromServer = data.responseMessage.impianti;

                        this.allTipologieFromServer = data.responseMessage.tipologie;
                        this.allFormatiFromServer = data.responseMessage.formati;
                        this.allTipiilluminazioneFromServer = data.responseMessage.tipiilluminazione;
                        this.allConcessionariFromServer = data.responseMessage.concessionari;
                        this.allCategorieFromServer = data.responseMessage.categorie;

                        //controllo se l'URI attuale contiene dei filtri attivi e li metto nei valori attivi delle select
                        if(this.s!=null){
                            try{
                                var filterFromURI = (uriToJSON(this.s)).filters || null;

                                var valueRegioni = [];
                                var valueProvince = [];
                                var valueComuni = [];

                                var valueTipologie = [];
                                var valueFormati = [];
                                var valueTipiilluminazione = [];
                                var valueConcessionari = [];
                                var valueCategorie = [];

                                if(filterFromURI != null){
                                    //REGIONI from URL
                                    if(filterFromURI.regioni != null && filterFromURI.regioni.length>0){
                                        for(let i=0;i<filterFromURI.regioni.length;i++){
                                            let val = filterFromURI.regioni[i];
                                            let index = data.responseMessage.regioni.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.regioni[index]){
                                                valueRegioni.push(data.responseMessage.regioni[index]);
                                                this.selectedIDRegioni.push(data.responseMessage.regioni[index].id);
                                                this.selectedItemsRegioni.push(data.responseMessage.regioni[index]);
                                            }
                                        }
                                    }
                                    //PROVINCE from URL
                                    if(filterFromURI.province != null && filterFromURI.province.length>0){
                                        for(let i=0;i<filterFromURI.province.length;i++){
                                            let val = filterFromURI.province[i];
                                            let index = data.responseMessage.province.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.province[index]){
                                                valueProvince.push(data.responseMessage.province[index]);
                                                this.selectedIDProvince.push(data.responseMessage.province[index].id);
                                                this.selectedItemsProvince.push(data.responseMessage.province[index]);
                                            }
                                        }
                                    }
                                    //COMUNI from URL
                                    if(filterFromURI.comuni != null && filterFromURI.comuni.length>0){
                                        for(let i=0;i<filterFromURI.comuni.length;i++){
                                            let val = filterFromURI.comuni[i];
                                            let index = data.responseMessage.comuni.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.comuni[index]){
                                                valueComuni.push(data.responseMessage.comuni[index]);
                                                this.selectedIDComuni.push(data.responseMessage.comuni[index].id);
                                                this.selectedItemsComuni.push(data.responseMessage.comuni[index]);
                                            }
                                        }
                                    }

                                    //TIPOLOGIE from URL
                                    if(filterFromURI.tipologie != null && filterFromURI.tipologie.length>0){
                                        for(let i=0;i<filterFromURI.tipologie.length;i++){
                                            let val = filterFromURI.tipologie[i];
                                            let index = data.responseMessage.tipologie.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.tipologie[index]){
                                                valueTipologie.push(data.responseMessage.tipologie[index]);
                                                this.selectedIDTipologie.push(data.responseMessage.tipologie[index].id);
                                            }
                                        }
                                    }
                                    //FORMATI from URL
                                    if(filterFromURI.formati != null && filterFromURI.formati.length>0){
                                        for(let i=0;i<filterFromURI.formati.length;i++){
                                            let val = filterFromURI.formati[i];
                                            let index = data.responseMessage.formati.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.formati[index]){
                                                valueFormati.push(data.responseMessage.formati[index]);
                                                this.selectedIDFormati.push(data.responseMessage.formati[index].id);
                                            }
                                        }
                                    }
                                    //TIPIILLUMINAZIONE from URL
                                    if(filterFromURI.tipiilluminazione != null && filterFromURI.tipiilluminazione.length>0){
                                        for(let i=0;i<filterFromURI.tipiilluminazione.length;i++){
                                            let val = filterFromURI.tipiilluminazione[i];
                                            let index = data.responseMessage.tipiilluminazione.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.tipiilluminazione[index]){
                                                valueTipiilluminazione.push(data.responseMessage.tipiilluminazione[index]);
                                                this.selectedIDTipiilluminazione.push(data.responseMessage.tipiilluminazione[index].id);
                                            }
                                        }
                                    }
                                    //CONCESSIONARI from URL
                                    if(filterFromURI.concessionari != null && filterFromURI.concessionari.length>0){
                                        for(let i=0;i<filterFromURI.concessionari.length;i++){
                                            let val = filterFromURI.concessionari[i];
                                            let index = data.responseMessage.concessionari.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.concessionari[index]){
                                                valueConcessionari.push(data.responseMessage.concessionari[index]);
                                                this.selectedIDConcessionari.push(data.responseMessage.concessionari[index].id);
                                            }
                                        }
                                    }
                                    //CATEGORIE from URL
                                    if(filterFromURI.categorie != null && filterFromURI.categorie.length>0){
                                        for(let i=0;i<filterFromURI.categorie.length;i++){
                                            let val = filterFromURI.categorie[i];
                                            let index = data.responseMessage.categorie.findIndex(function(item, i){
                                                return item.id === val;
                                            });
                                            if(index!==undefined && index!==null && data.responseMessage.categorie[index]){
                                                valueCategorie.push(data.responseMessage.categorie[index]);
                                                this.selectedIDCategorie.push(data.responseMessage.categorie[index].id);
                                            }
                                        }
                                    }
                                }
                                this.setState({ 
                                    isLoading:false, 
                                    selectedRegioni: valueRegioni,
                                    selectedProvince: valueProvince,
                                    selectedComuni: valueComuni,
                                    selectedTipologie: valueTipologie,
                                    selectedFormati: valueFormati,
                                    selectedTipiilluminazione: valueTipiilluminazione,
                                    selectedConcessionari: valueConcessionari,
                                    selectedCategorie: valueCategorie,
                                    regioniForSelect: data.responseMessage.regioni, 
                                    provinceForSelect: data.responseMessage.province, 
                                    comuniForSelect: data.responseMessage.comuni,
                                    tipologieForSelect: data.responseMessage.tipologie,
                                    formatiForSelect: data.responseMessage.formati,
                                    tipiilluminazioneForSelect: data.responseMessage.tipiilluminazione,
                                    concessionariForSelect: data.responseMessage.concessionari,
                                    categorieForSelect: data.responseMessage.categorie
                                }, () => {
                                    if(this.mapLoaded === true){
                                        this.getMarkersAfterMapLoad = false;
                                        this.getImpiantiWithTheseFilters();
                                    }else{
                                        this.getMarkersAfterMapLoad = true;
                                    }
                                });                                
                                
                            }catch(Exception){
                                //L'URL era formattato in modo sbagliato
                                this.props.history.replace("/map");
                                this.setState({ 
                                    isLoading:false, 
                                    regioniForSelect: data.responseMessage.regioni, 
                                    provinceForSelect: data.responseMessage.province, 
                                    comuniForSelect: data.responseMessage.comuni,
                                    tipologieForSelect: data.responseMessage.tipologie,
                                    formatiForSelect: data.responseMessage.formati,
                                    tipiilluminazioneForSelect: data.responseMessage.tipiilluminazione,
                                    concessionariForSelect: data.responseMessage.concessionari,
                                    categorieForSelect: data.responseMessage.categorie
                                }, () => {
                                    if(this.mapLoaded === true){
                                        this.getMarkersAfterMapLoad = false;
                                        this.getImpiantiWithTheseFilters();
                                    }else{
                                        this.getMarkersAfterMapLoad = true;
                                    }
                                });
                            }
                        }else{
                            this.setState({ 
                                isLoading:false, 
                                regioniForSelect: data.responseMessage.regioni, 
                                provinceForSelect: data.responseMessage.province, 
                                comuniForSelect: data.responseMessage.comuni,
                                tipologieForSelect: data.responseMessage.tipologie,
                                formatiForSelect: data.responseMessage.formati,
                                tipiilluminazioneForSelect: data.responseMessage.tipiilluminazione,
                                concessionariForSelect: data.responseMessage.concessionari,
                                categorieForSelect: data.responseMessage.categorie
                            }, () => {
                                if(this.mapLoaded === true){
                                    this.getMarkersAfterMapLoad = false;
                                    this.getImpiantiWithTheseFilters();
                                }else{
                                    this.getMarkersAfterMapLoad = true;
                                }
                            });
                        }

                    }else{
                        this.setState({ErrorSnack: <SnackBars type="error" message={"Empty response"}/>});
                    }
                }else{
                    if(obj.status === 401){
                        Logout();
                        this.props.history.replace('/login');                                        
                    }else{
                        this.setState({ErrorSnack: <SnackBars type="error" message={"Server Error"}/>});                    
                    }
                }
            });
    }

    handleToggleLeftMenu = (e) => {
        this.leftMenuToggleIcon.current.classList.toggle("left-pane-toggle-button-icon-collapsed");
        this.leftMenu.current.classList.toggle("left-pane-collapsed");
        this.mapSection.current.classList.toggle("closedMenu");
    }

    handleChangePaneSection = (e, newValue) => {
        this.setState({ menuSection: newValue });
        switch(newValue){
            case 0:
                this.resultsMenuSection.current.classList.add("collapsed");
                this.filtersMenuSection.current.classList.remove("collapsed");
                break;
            case 1:
                this.filtersMenuSection.current.classList.add("collapsed");
                this.resultsMenuSection.current.classList.remove("collapsed");
                break;
            default:
                break;
        }
    }

    selectedCardsForCustomDownload = (impiantoId, e) => {

        if(impiantoId == null && e == null){
            //Checkboxes Reset
            var checkboxes = document.getElementsByName("checkboxCardsImpianti");
            for(var i = 0; i < checkboxes.length; i++){  
                if(checkboxes[i].checked)  
                    checkboxes[i].click();  
            } 
            this.setState({
                selectedImpiantiForCustomDownload: []
            });
        }else{
            let newValues = this.state.selectedImpiantiForCustomDownload;
            if(e.target.checked){
                //Aggiungo Impianto
                newValues.push(impiantoId);
                this.setState({
                    selectedImpiantiForCustomDownload: newValues
                });
            }else{
                //Rimuovo Impianto
                let oldIndex = newValues.indexOf(impiantoId);
                if(oldIndex > -1){
                    newValues.splice(oldIndex, 1);
                    this.setState({
                        selectedImpiantiForCustomDownload: newValues
                    });
                }
            }
        }

    }

    handleMessageSnackClosing = () => {
        this.setState({
            messageSnackOpen: false,
            messageSnackMessage: '',
            messageSnackType: ''
        });
    }

    handleReturnToDashboardClick = () => {
        window.location = "/dashboard";
    }

    pointMapFromDetail = (id) =>{
        this.mappa.current.focusThisMarker(id);
        var cardOfThisId = document.getElementById('cardimpianto-'+id);
        cardOfThisId.scrollIntoView({behavior: "smooth", block: "center"});
    }

    getImpiantiWithTheseFilters = () =>{
        var filters = {};
        filters.regioni = this.selectedIDRegioni;
        filters.province = this.selectedIDProvince;
        filters.comuni = this.selectedIDComuni;
        filters.tipologie = this.selectedIDTipologie;
        filters.formati = this.selectedIDFormati;
        filters.tipiilluminazione = this.selectedIDTipiilluminazione;
        filters.concessionari = this.selectedIDConcessionari;
        filters.categorie = this.selectedIDCategorie;

        this.setState({gettingResults: true, resultsPaneSectionDisabled: true}, () => {
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ filters: filters })
            };
            fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    if(data.messageType !== null && data.messageType === "response" && data.responseMessage.impianti !== null){
                        if(data.responseMessage.impianti.length > 0){
                            //riempo la sezione risultati con le info di tutti gli impianti
                            let tempArrayRisultati = [];
                            for(let i=0;i<data.responseMessage.impianti.length;i++){
                                tempArrayRisultati.push(
                                    <CardImpianto 
                                        key={'cardimpianto-'+data.responseMessage.impianti[i].id} 
                                        impianto={data.responseMessage.impianti[i]} 
                                        impiantoMapCallback={this.pointMapFromDetail}
                                        selectedCardsForCustomDownload={this.selectedCardsForCustomDownload}
                                        reloadResultsCallback={this.getImpiantiWithTheseFilters}
                                    />);
                            }

                            this.setState({
                                resultsPaneContent: null
                            }, ()=>{
                                this.setState({
                                    resultsNumber: data.responseMessage.impianti.length, 
                                    markersForMap: data.responseMessage.impianti,
                                    resultsPaneContent: tempArrayRisultati
                                });
                            });
                            
                            
                        }else{
                            this.setState({
                                resultsNumber: 0,
                                markersForMap: [],
                                resultsPaneContent: null
                            });
                        }
                        
                    }else{

                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }else{
                            this.setState({ 
                                resultsNumber: 0
                            });
                            console.error("Error on getting Impianti: "+data);
                        }
                        
                    }

                    this.setState({
                        inputResultsSearch: '',
                        resultsPaneSectionDisabled: false,
                        gettingResults: false
                    });

                });

        });
        
    }

    setFilters = (newValue, filterType) =>{
        var selectedIds = [];
        var selectedItems = [];
        if(newValue.length!=null && newValue.length>0){
            for(let i=0;i<newValue.length;i++){
                if(newValue[i].id !== null  && newValue[i].id !== undefined){
                    selectedIds.push(newValue[i].id);
                }
            }
        }
        switch(filterType){
            case 'regione':
                this.selectedIDRegioni=selectedIds;

                if(selectedIds.length === 0){

                    //Controllo se ci sono province attive e le applico ai comuni, senò metto tutti i comuni
                    let comuniFilteredFromProvince = [];
                    let idProvinceCorrenti = this.selectedIDProvince;
                    if(this.selectedIDProvince !== null && this.selectedIDProvince.length > 0){    
                        comuniFilteredFromProvince = this.allComuniFromServer.filter(
                            function(item){
                                let ok = false;
                                for(let i=0;i<this.length;i++){
                                    if(this[i] === item.idProvincia){
                                        ok = true;
                                    }
                                }
                                return ok;
                            },
                            idProvinceCorrenti
                        )
                    }else{
                        comuniFilteredFromProvince = this.allComuniFromServer;
                    }

                    this.setState({provinceForSelect: this.allProvinceFromServer, comuniForSelect: comuniFilteredFromProvince});
                }else{

                    //Metto nell'elenco delle province solo quelle della regione selezionata
                    let filteredOptionsProvince = this.allProvinceFromServer.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idRegione){
                                    ok = true;
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );

                    //Metto nell'elenco dei comuni solo quelli della regione selezionata
                    let filteredOptionsComuni = this.allComuniFromServer.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idRegione){
                                    ok = true;
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );

                    //Controllo che le province già selezionate rispettino la nuova regione selezionata (senò le elimino)
                    let filteredSelectedProvinceID = [];
                    let filteredSelectedProvinceItems = this.selectedItemsProvince.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idRegione){
                                    ok = true;
                                    filteredSelectedProvinceID.push(item.id);
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );
                    this.selectedItemsProvince = filteredSelectedProvinceItems;
                    this.selectedIDProvince = filteredSelectedProvinceID;
                    
                    //Controllo che i comuni già selezionati rispettino la nuova regione selezionata (senò li elimino)
                    let filteredSelectedComuniID = [];
                    let filteredSelectedComuniItems = this.selectedItemsComuni.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idRegione){
                                    ok = true;
                                    filteredSelectedComuniID.push(item.id);
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );
                    this.selectedItemsComuni = filteredSelectedComuniItems;
                    this.selectedIDComuni = filteredSelectedComuniID;


                    this.setState({provinceForSelect: filteredOptionsProvince, comuniForSelect: filteredOptionsComuni, selectedProvince: filteredSelectedProvinceItems, selectedComuni: filteredSelectedComuniItems});
                }
                break;
            case 'provincia':
                this.selectedIDProvince=selectedIds;

                //Mi salvo gli item relativi agli ID selezionati
                for(let i=0;i<this.allProvinceFromServer.length;i++){    
                    for(let a=0;a<selectedIds.length;a++){
                        if(this.allProvinceFromServer[i].id === selectedIds[a]){
                            selectedItems.push(this.allProvinceFromServer[i]);
                        }
                    }
                }
                this.selectedItemsProvince = selectedItems;



                if(selectedIds.length === 0){
                    
                    //Controllo se ci sono regioni attive e le applico ai comuni, senò metto tutti i comuni
                    let comuniFilteredFromRegioni = [];
                    let idRegioniCorrenti = this.selectedIDRegioni;
                    if(this.selectedIDRegioni !== null && this.selectedIDRegioni.length > 0){    
                        comuniFilteredFromRegioni = this.allComuniFromServer.filter(
                            function(item){
                                let ok = false;
                                for(let i=0;i<this.length;i++){
                                    if(this[i] === item.idRegione){
                                        ok = true;
                                    }
                                }
                                return ok;
                            },
                            idRegioniCorrenti
                        )
                    }else{
                        comuniFilteredFromRegioni = this.allComuniFromServer;
                    }
                    this.setState({comuniForSelect: comuniFilteredFromRegioni});
                    
                }else{

                    //Metto nell'elenco dei comuni solo quelli della provincia selezionata
                    let filteredOptionsComuni = this.allComuniFromServer.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idProvincia){
                                    ok = true;
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );

                    //Controllo che i comunui già selezionati rispettino la nuova provincia selezionata
                    let filteredSelectedComuniID = [];
                    let filteredSelectedComuniItems = this.selectedItemsComuni.filter(
                        function(item){
                            let ok = false;
                            for(let i=0;i<this.length;i++){
                                if(this[i] === item.idProvincia){
                                    ok = true;
                                    filteredSelectedComuniID.push(item.id);
                                }
                            }
                            return ok;
                        },
                        selectedIds
                    );
                    this.selectedItemsComuni = filteredSelectedComuniItems;
                    this.selectedIDComuni = filteredSelectedComuniID;


                    this.setState({comuniForSelect: filteredOptionsComuni, selectedComuni: filteredSelectedComuniItems});
                }
                break;
            case 'comune':
                this.selectedIDComuni=selectedIds;

                //Mi salvo gli item relativi agli ID selezionati 
                for(let i=0;i<this.allComuniFromServer.length;i++){    
                    for(let a=0;a<selectedIds.length;a++){
                        if(this.allComuniFromServer[i].id === selectedIds[a]){
                            selectedItems.push(this.allComuniFromServer[i]);
                        }
                    }
                }
                this.selectedItemsComuni = selectedItems;
                break;
            case 'tipologia':
                this.selectedIDTipologie=selectedIds;
                //per questi filtri non controllo gli elementi selezionati, in quanto non hanno delle regole, lascio quelli selezionati dall'utente
                break;
            case 'formato':
                this.selectedIDFormati=selectedIds;
                //per questi filtri non controllo gli elementi selezionati, in quanto non hanno delle regole, lascio quelli selezionati dall'utente
                break;
            case 'tipoilluminazione':
                this.selectedIDTipiilluminazione=selectedIds;
                //per questi filtri non controllo gli elementi selezionati, in quanto non hanno delle regole, lascio quelli selezionati dall'utente
                break;
            case 'concessionario':
                this.selectedIDConcessionari=selectedIds;
                //per questi filtri non controllo gli elementi selezionati, in quanto non hanno delle regole, lascio quelli selezionati dall'utente
                break;
            case 'categoria':
                this.selectedIDCategorie=selectedIds;
                //per questi filtri non controllo gli elementi selezionati, in quanto non hanno delle regole, lascio quelli selezionati dall'utente
                break;
            default:
                break;
        }
        
        

        
        //Setting URL for sharing
        if( (this.selectedIDRegioni!=null && this.selectedIDRegioni.length>0) || 
            (this.selectedIDProvince!=null && this.selectedIDProvince.length>0) || 
            (this.selectedIDComuni!=null && this.selectedIDComuni.length>0) || 
            (this.selectedIDTipologie!=null && this.selectedIDTipologie.length>0) || 
            (this.selectedIDFormati!=null && this.selectedIDFormati.length>0) || 
            (this.selectedIDTipiilluminazione!=null && this.selectedIDTipiilluminazione.length>0) || 
            (this.selectedIDConcessionari!=null && this.selectedIDConcessionari.length>0) || 
            (this.selectedIDCategorie!=null && this.selectedIDCategorie.length>0)
        ){
            var obj = {};
            obj.filters = {};
            if(this.selectedIDRegioni!=null && this.selectedIDRegioni.length>0){
                obj.filters.regioni = this.selectedIDRegioni;
            }
            if(this.selectedIDProvince!=null &&this.selectedIDProvince.length>0){
                obj.filters.province = this.selectedIDProvince;
            }
            if(this.selectedIDComuni!=null &&this.selectedIDComuni.length>0){
                obj.filters.comuni = this.selectedIDComuni;
            }
            if(this.selectedIDTipologie!=null &&this.selectedIDTipologie.length>0){
                obj.filters.tipologie = this.selectedIDTipologie;
            }
            if(this.selectedIDFormati!=null &&this.selectedIDFormati.length>0){
                obj.filters.formati = this.selectedIDFormati;
            }
            if(this.selectedIDTipiilluminazione!=null &&this.selectedIDTipiilluminazione.length>0){
                obj.filters.tipiilluminazione = this.selectedIDTipiilluminazione;
            }
            if(this.selectedIDConcessionari!=null &&this.selectedIDConcessionari.length>0){
                obj.filters.concessionari = this.selectedIDConcessionari;
            }
            if(this.selectedIDCategorie!=null &&this.selectedIDCategorie.length>0){
                obj.filters.categorie = this.selectedIDCategorie;
            }
            this.currentUrlSearchParameter = "s="+jsonToURI(obj);
            this.props.history.replace("/map?s="+jsonToURI(obj));            
        }else{
            this.props.history.replace("/map");
        }

        this.getImpiantiWithTheseFilters();
    }


    handleMapReady = () =>{
        this.mapLoaded = true;
        if(this.getMarkersAfterMapLoad === true){
            this.getImpiantiWithTheseFilters();
        }
        this.getMarkersAfterMapLoad = false;
    }


    handleClickMaker = (markerId) =>{
        //Mostro la card relativa al marker selezionato. null -> ho riposizionato la mappa al centro
        if(markerId == null){
            console.log('riposiziono la mappa');
        }else{
            console.log(markerId);
            this.filtersMenuSection.current.classList.add("collapsed");
            this.resultsMenuSection.current.classList.remove("collapsed");
            this.setState({
                menuSection: 1
            }, () =>{

                var cardOfThisId = document.getElementById('cardimpianto-'+markerId);
                cardOfThisId.scrollIntoView({behavior: "smooth", block: "center"});

                this.leftMenuToggleIcon.current.classList.remove("left-pane-toggle-button-icon-collapsed");
                this.leftMenu.current.classList.remove("left-pane-collapsed");
                this.mapSection.current.classList.remove("closedMenu");
                
            });
            
        }
        
    }

    downloadXlsFile = (action) =>{
        if(action === "custom"){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ customdownloadfromid: this.state.selectedImpiantiForCustomDownload, export:"csv" })
            };
            fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = "PosizioniSelezionate_"+Date.now()+".xlsx";
                    document.body.appendChild(a);
                    a.click();    
                    a.remove();       
                });
        }else if(action === ""){
            
        }else{
            var filters = {};
            filters.regioni = this.selectedIDRegioni;
            filters.province = this.selectedIDProvince;
            filters.comuni = this.selectedIDComuni;
            filters.tipologie = this.selectedIDTipologie;
            filters.formati = this.selectedIDFormati;
            filters.tipiilluminazione = this.selectedIDTipiilluminazione;
            filters.concessionari = this.selectedIDConcessionari;
            filters.categorie = this.selectedIDCategorie;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ filters: filters, export:"csv" })
            };
            fetch(config.SERVER_ADDR+apiRoutes.search, requestOptions)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = "Posizioni_"+Date.now()+".xlsx";
                    document.body.appendChild(a);
                    a.click();    
                    a.remove();       
                });
        }
        
    }


    addFavoriteToAccount = () =>{
        Swal.fire({
            title: 'Salva ricerca nei preferiti',
            focusConfirm: true,
            showClass: {
                popup: '' //disable popup animation
            },
            html:
            '<div class="newFavSwal">'+
                '<label class="introLabel">Salva la ricerca nei tuoi preferiti per poterla riaprire velocemente un\'altra volta!</label>'+
                '<div class="newFavSwal-input"><label>Nome</label><input autocomplete="off" id="swal-input1-name" class="swal2-input"></div>' +
                '<div class="newFavSwal-input"><label>Descrizione</label><input autocomplete="off" id="swal-input2-descr" class="swal2-input"></div>'+
            '</div>',
            showCancelButton: true,
            confirmButtonColor: config.themeColor,
            preConfirm: () => {
                let nameNewFav = document.getElementById('swal-input1-name').value.trim();
                let descrNewFav = document.getElementById('swal-input2-descr').value.trim();
                let currentUrlSearchParams = this.currentUrlSearchParameter.trim();
                if(nameNewFav !== "" && descrNewFav !== "" && currentUrlSearchParams !== ""){
                    
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            extFunction: "newFavoriteMap", 
                            title: nameNewFav, 
                            description: descrNewFav,
                            url: currentUrlSearchParams
                        })
                    };
                    fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                        .then(response => response.json().then(data => ({status: response.status, body: data})))
                        .then(obj => {
                            let data = obj.body;
                            if(data.messageType !== null && data.messageType === "response"){
                                
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Mappa salvata tra i preferiti!',
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                
                            }else{

                                if(obj.status === 401){
                                    Logout();
                                    this.props.history.replace('/login');                                        
                                }else{
                                    this.setState({
                                        messageSnackOpen: true,
                                        messageSnackMessage: 'Errore nel salvataggio',
                                        messageSnackType: 'error'
                                    })
                                }
                                
                            }                           

                        });

                }else{
                    Swal.fire('Inserisci entrami i valori: Titolo e Descrizione');
                }
            }
        });
    }

    handleChangeInput = (event, stateName) =>{
        this.setState({
            [stateName]:event.target.value
        })

        switch(stateName){
            case 'inputResultsSearch':
                //Filtro gli impianti con la nuova ricerca
                let currentSearchArray = event.target.value.toLowerCase().trim().split(" ");
                let cardsImpiantiAttuali = document.getElementsByClassName('CardImpianto');
                for(let i=0;i<cardsImpiantiAttuali.length;i++){
                    let currentCardValue = cardsImpiantiAttuali[i].getAttribute('data-indirizzo').toLowerCase();
                    if(!currentSearchArray.every(function(word){return currentCardValue.indexOf(word)>-1})){
                        cardsImpiantiAttuali[i].style.display = 'none';
                    }else{
                        cardsImpiantiAttuali[i].style.display = 'block'; 
                    }
                }
                break;
            default:
                break;
        }
    }


    render(){

        let resultsPaneIcon= '';
        if(this.state.gettingResults === true){
            resultsPaneIcon = <ReactLoading height={20} width={20} type={"spin"} color={config.themeColor} />;
        }else{
            resultsPaneIcon = <StyledBadge badgeContent={this.state.resultsNumber} color="primary"> <FileCopyIcon /></StyledBadge>;
        }

        return(
            <div>
                { this.state.isLoading ? (
                    <div>
                        <div className="layoutLoadingAnimation"> 
                            <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                        </div>
                        {this.state.ErrorSnack}
                    </div>
                ) : (
                    <div className="MapPage-page">
                        <div className="left-pane" ref={this.leftMenu}>
                            <div className="left-pane-toggle-container">
                                <button className="left-pane-toggle-button" onClick={this.handleToggleLeftMenu}>
                                    <img alt="" src={closeIconleftMenu} ref={this.leftMenuToggleIcon} className="left-pane-toggle-button-icon"/>
                                </button>
                            </div>

                            <div className="pane-container">
                                <Paper square className="leftSectionsMenu">
                                    <Tabs
                                        variant="fullWidth"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="icon label tabs example"
                                        value={this.state.menuSection}
                                        onChange={this.handleChangePaneSection}
                                    >
                                        <Tab icon={<FilterListIcon />} aria-label="filtri" />
                                        <Tab icon={resultsPaneIcon} disabled={this.state.resultsPaneSectionDisabled} aria-label="risultati" />
                                    </Tabs>
                                </Paper>

                                <div className="pane-section" ref={this.filtersMenuSection}>
                                    <h1 className="pane-section-title title-filters">
                                        Filtri
                                        <span className="filtersActionsLayout">
                                            <IconButton aria-label="favorite" size="small" className="favoritesButton filtersActions" onClick={this.addFavoriteToAccount}>
                                                <FavoriteIcon fontSize="inherit" />
                                            </IconButton>
                                        </span>
                                    </h1>
                                    
                                    <div className="filters-container">
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Regione</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedRegioni} identifier={'regione'} options={this.state.regioniForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Provincia</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedProvince} identifier={'provincia'} options={this.state.provinceForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Comune</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedComuni} hugeOptions={true} identifier={'comune'} options={this.state.comuniForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Tipologia</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedTipologie} identifier={'tipologia'} groupBy={false} options={this.state.tipologieForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Formato</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedFormati} identifier={'formato'} groupBy={false} options={this.state.formatiForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Tipo Illuminazione</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedTipiilluminazione} identifier={'tipoilluminazione'}  groupBy={false} options={this.state.tipiilluminazioneForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Concessionario</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedConcessionari} identifier={'concessionario'} options={this.state.concessionariForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                        <div className="filter-container">
                                            <h3 className="filter-inner-title">Categoria</h3>
                                            <div className="filter-inner-container">
                                                <AutocompleteSelect selectedValues={this.state.selectedCategorie} identifier={'categoria'} groupBy={false} options={this.state.categorieForSelect} filterOnChange={true} onChangeFunction={this.setFilters} colorChips={'primary'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pane-section collapsed" ref={this.resultsMenuSection}>
                                    <h1 className="pane-section-title title-results">
                                        Risultati: {this.state.resultsNumber}
                                        {
                                            (this.state.resultsNumber > 0)? 
                                                <span className="resultsActionsLayout">
                                                    <IconButton aria-label="download" size="small" className="downloadButton resultsActions" onClick={()=>this.downloadXlsFile()}>
                                                        <ArrowDownwardIcon fontSize="inherit" />
                                                    </IconButton>

                                                    { (this.state.selectedImpiantiForCustomDownload.length !== null && this.state.selectedImpiantiForCustomDownload.length > 0)?
                                                        <span>
                                                            <IconButton aria-label="custom-download" size="small" className="customDownloadButton resultsActions" onClick={()=>this.downloadXlsFile('custom')}>
                                                                <BeenhereIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <IconButton aria-label="custom-download-deselect" size="small" className="customDownloadDeselectButton resultsActions" onClick={()=>this.selectedCardsForCustomDownload(null,null)}>
                                                                <SelectAllIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </span>
                                                    :''}
                                                </span>
                                            : ''
                                        }

                                        {
                                            (this.state.resultsNumber > 0)? 
                                                <span>
                                                    <TextField
                                                        id="input-results-search"
                                                        className="input-search-results"
                                                        placeholder="Cerca tra gli indirizzi"
                                                        variant="outlined"
                                                        value={this.state.inputResultsSearch}
                                                        onChange={(e)=>{this.handleChangeInput(e, 'inputResultsSearch')}}
                                                        InputProps={{
                                                            autoComplete:"off",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </span>
                                            : ''
                                        }
                                        
                                    </h1>
                                    <div className="results-container">
                                        {this.state.resultsPaneContent}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="returnToDashboardButton" onClick={this.handleReturnToDashboardClick}>
                            <DashboardIcon fontSize="large"/>
                        </div>
                        <div className="map" id="map" ref={this.mapSection}>
                            <Map
                                ref={this.mappa}
                                posizioni={this.state.markersForMap} 
                                loading={this.state.gettingResults} 
                                onClickMarker={this.handleClickMaker} 
                                onMapReady={this.handleMapReady}
                            />
                        </div>
                        <Snackbar open={this.state.messageSnackOpen} autoHideDuration={3000} onClose={this.handleMessageSnackClosing}>
                            <Alert severity={this.state.messageSnackType} onClose={this.handleMessageSnackClosing}>
                                {this.state.messageSnackMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(MapPage);