import { Component } from "react";
import {
    Link
} from "react-router-dom";

import './Page404.scss';

export default class Page404 extends Component{

    render(){
        return(
            <div className="notfound404-page">
                <div id="notfound">
                    <div className="notfound">
                        <div className="notfound-404">
                            <h1>404</h1>
                            <h2>Pagina non trovata</h2>
                        </div>
                        <Link to="/">Go Home</Link>
                    </div>
                </div>
            </div>
        )
    }

}