import React, { Component } from "react";
import { withRouter } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import red from '@material-ui/core/colors/red';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import "./Header.scss";
import {config, apiRoutes, Logout} from  "../../../../Utils/global";

class Header extends Component{

    constructor(props){
        super(props);
        this.isCorrectlyLogged = this.props.isCorrectlyLogged;
        this.state = {
            anchorEl: null
        }
    }

    handleClose = (action) =>{
        switch(action){
            case 'profile':
                this.props.history.push('/settings/profile');
                break;
            case "settings":
                this.props.history.push('/settings/home');
                break;
            case 'logout':
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ extFunction: "logout" })
                };
                fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                    .then(() => {
                        Logout();
                        window.location.reload();
                    });
                break;
            default:
                break;
        }
        this.setState({anchorEl: null});
    }

    handleClick = (e) =>{
        this.setState({anchorEl: e.currentTarget});
    }

    headerButton = (action) =>{
        switch(action){
            case "dashboard":
                this.props.history.push('/dashboard');
                break;
            default:
                break;
        }
    }

    componentDidMount(){
    }

    render(){
        
        if(this.isCorrectlyLogged === true){
            return(
                <div className="header">
                    <IconButton title={"Dashboard"} size="medium" className="header-item" onClick={()=>{this.headerButton("dashboard")}}>
                        <DashboardIcon fontSize="small"/>
                    </IconButton>
                    <IconButton title={"Profilo"} size="medium" className="header-item user" onClick={this.handleClick}>
                        <PersonIcon fontSize="small"/>
                    </IconButton>
                    <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    >
                        <MenuItem onClick={()=>{this.handleClose('profile')}}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Profilo
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={()=>{this.handleClose('settings')}}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Impostazioni
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={()=>{this.handleClose('logout')}}>
                            <ListItemIcon>
                                <ExitToAppIcon  style={{ color: red[500] }} fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit" style={{ color: red[500] }} noWrap>
                                Logout
                            </Typography>
                        </MenuItem>
                    </Menu>
                </div>
            );
        }else{
            return(
                <div className="header">
                    <Button variant="contained" className="header-item loginButton" href="/login">
                        Login
                    </Button>
                </div>
            );
        }
        
    }
}

export default withRouter(Header);