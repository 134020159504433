import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Switch, Route/*, Redirect*/ } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';

//import SearchResults from './pages/SearchResults/SearchResults';
import Page404 from './pages/Page404/Page404';
import Login from './pages/Login/Login';
import MapPage from './pages/MapPage/MapPage';
import Dashboard from './pages/Dashboard/Dashboard';
import Home from "./pages/Home/Home";
import {AppTheme, config} from  "./Utils/global";
import {isLogged} from './pages/Login/LoginServices';
import Settings from './pages/Settings/Settings';

class App extends Component{

  constructor(props){
      super(props);
      this.state = {
        isLoading: true
      }
      
  }

  componentDidMount(){
    if(!isLogged()){
      var redirectQ = (new URL(window.location.href)).searchParams.get("redirect");
      if(redirectQ !== null){
        console.log(window.location.href);
        this.setState ({ isLoading: false });
      }else{
        let currentPathName = this.props.history.location.pathname;
        if(currentPathName !== "/" && currentPathName !== "/login"){
          let tempLink = window.location.href.replace(window.location.origin+'/','');
          this.props.history.replace('/login?redirect='+tempLink);
        }
        this.setState ({ isLoading: false });        
      }
    }else{
      this.setState ({ isLoading: false });
    }
  }

  render(){
      return(
          <div>
            <ThemeProvider theme={AppTheme}>
                { this.state.isLoading ? (

                    <div className="layoutLoadingAnimation"> 
                        <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                    </div>

                ) : (

                  <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/login" component={Login} />
                    <Route exact path="/map" component={MapPage} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path={["/settings/:sub", "/settings"]} component={Settings} />
                    {/*<Route exact path="/search/:query" component={SearchResults} />
                    <Route path="/search">
                      <Redirect to="/"/>
                    </Route>*/}
                    <Route component={Page404}/>
                  </Switch>

                )}
            </ThemeProvider>
        </div>
      );
  }
}

export default withRouter(App);
