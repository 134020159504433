import React, { Component } from "react";

import {config} from  "../../../Utils/global";
import "./ImageSliderImpianto.scss";

export default class ImageSliderImpianto extends Component{

    constructor(props){
        super(props);
        this.thisSliderContainer = null;
        this.state = {
            images: this.props.images,
            impiantoId: this.props.impiantoId,
            editMode: this.props.editMode,
            imageSlides: [],
            imageSlidesDots: [],
            imagesToDeleteOnServer: this.props.imagesToDeleteOnServer
        }
        this.imagesBackup = this.props.images;
        this.currentSliderImgN = 1;
        this.uploadForm = null;
        this.imgToUploadToServer = [];

        this.formUploadImpiantoId = React.createRef();
    }

    setImpiantoId = (newImpiantoId) => {
        this.setState({
            impiantoId: newImpiantoId
        }, () => {
            this.formUploadImpiantoId.current.value = newImpiantoId;
        })
    }

    componentDidMount(){
        this.updateSliderImages();

        document.addEventListener('keydown', (e)=>this.handleArrowPress(e), false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleArrowPress, false);
    }    

    handleArrowPress = (e) => {
        if(e.key === "ArrowLeft" || e.key === "ArrowRight" ){
            if(e.key === "ArrowLeft"){
                this.prevnextImageSlide(-1);
            }else{
                this.prevnextImageSlide(1);
            }
        }
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.editMode !== this.props.editMode){
            this.setState({
                editMode: this.props.editMode
            }, () => {
                this.updateSliderImages();
                if(!this.props.editMode){
                    this.showSlide(1);
                }
            });
        }
    }

    uploadImageEvent = (e) => {
        let files = this.uploadForm.files

        if(files){

            let newImages;
            if(this.imagesBackup){
                newImages = this.imagesBackup.split(",");
            }else{
                newImages = [];
            }

            if(files.length > 1){
                for(let i=0; i<files.length; i++){
                    let file = files[i];
                    newImages.push(URL.createObjectURL(file));
                }
                this.setState({
                    images: newImages.join(",")
                }, () => {
                    this.currentSliderImgN += files.length;
                    this.updateSliderImages();
                });
            }else{
                let [file] = files;
                newImages.push(URL.createObjectURL(file));
                this.setState({
                    images: newImages.join(",")
                }, () => {
                    this.currentSliderImgN += 1;
                    this.updateSliderImages();
                });
            }
        }
    }

    handleDeleteImgIconOnServer = (numSlide) => {
        let imagesN = this.state.images.split(",");
        
        let newImages = [];
        for(let i=0; i<imagesN.length; i++){
            if(i !== numSlide){
                newImages.push(imagesN[i]);
            }
        }

        let tmpArrBk = [];
        for(let i=0; i<newImages.length; i++){
            if(!newImages[i].startsWith("blob")){
                tmpArrBk.push(newImages[i]);
            }
        }
        this.imagesBackup = tmpArrBk.join(",");

        let newImagesToDeleteOnServer = this.state.imagesToDeleteOnServer;
        newImagesToDeleteOnServer.push(imagesN[numSlide]);
        this.setState({
            images: newImages.join(","),
            imagesToDeleteOnServer: newImagesToDeleteOnServer
        }, () => {
            if(this.currentSliderImgN !== 1){
                this.currentSliderImgN -= 1;
            }
            this.updateSliderImages();
            this.showSlide(this.currentSliderImgN);
        });
    }

    uploadSlide = (num1) => {
        let classes = "sliderSlide";
        if(num1 !== 0){
            classes += " hidden";
        }
        return(
            <div key={"sliderImage-new"} className={classes} onClick={()=>{this.uploadNewImage()}}>
                <div className="uploadSlide">
                    <form className="img-form-uploader" encType="multipart/form-data">
                        <input type="file" className="form-uploader-input-file" name="fileToUpload[]" id="fileToUpload" multiple ref={input => this.uploadForm = input} onChange={(e)=>this.uploadImageEvent(e)}/>
                        <input type="text" ref={this.formUploadImpiantoId} style={{display:'none'}} name="idImpianto" value={this.state.impiantoId}/>
                        <input type="submit" style={{display:'none'}} name="submitImgUpload"/>
                    </form>
                    <i className="fa fa-upload"/>
                </div>
            </div>
        )
    }
    newSlide = (img, i, totSlides, captionText) => {
        let classes = "sliderSlide";
        if(i !== 0){
            classes += " hidden";
        }
        let imgSrc = "";
        if(img.startsWith("blob")){
            imgSrc = img;
        }else{
            imgSrc = config.impiantiImagesPath+"/impianto"+this.state.impiantoId+"_"+img+"?dummy="+Date.now();
        }
        let deleteIcon;
        if(this.state.editMode){
            if(!img.startsWith("blob")){
                deleteIcon = <div className="deleteIconDiv"><span onClick={()=>this.handleDeleteImgIconOnServer(i)} className="deleteIconImg">Elimina</span></div>;
            }
        }
        return(
            <div key={"sliderImage-"+i} className={classes}>
                {/*<div className="numbertext">{i} / {totSlides}</div>*/}
                <img className="imgSlide" alt="" src={imgSrc}></img>
                {deleteIcon}
                {/*<div className="imgCaption">{captionText}</div>*/}
            </div>
        );
    }
    uploadSlideDot = (i) => {
        let classes = "dot";
        if(i === 0){
            classes += " active";
        }
        return(
            <span key={"sliderImageDot-new"} className={classes} onClick={()=>{this.showSlide(i+1)}}>+</span> 
        );
    }
    newSlideDot = (i, isBlob) => {
        let classes = "dot";
        if(i === 0){
            classes += " active";
        }
        if(isBlob){
            classes += " isBlob";
        }
        return(
            <span key={"sliderImageDot-"+i} className={classes} onClick={()=>{this.showSlide(i+1)}}></span> 
        );
    }

    prevnextImageSlide = (n) => {
        this.currentSliderImgN += n;
        this.showSlide(this.currentSliderImgN);
    }

    showSlide = (n) => {
        var i, slideIndex = n;
        if(this.thisSliderContainer == null){
            this.thisSliderContainer = document.getElementsByClassName("ImageSliderImpianto-"+this.state.impiantoId)[0];
        }
        var slides = this.thisSliderContainer.getElementsByClassName("sliderSlide");
        var dots = this.thisSliderContainer.getElementsByClassName("dot");
        if (n > slides.length) {slideIndex = 1}    
        if (n < 1) {slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].classList.add("hidden");  
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].classList.remove("active");
        }
        slides[slideIndex-1].classList.remove("hidden");
        dots[slideIndex-1].classList.add("active");

        this.currentSliderImgN = slideIndex;
    }

    uploadNewImage = () => {
        this.uploadForm.click();
    }


    updateSliderImages = () => {
        let tempImageSlides = [];
        let tempImageSlidesDots = [];
        if(this.state.images != null && this.state.images !== ""){
            let imagesN = this.state.images.split(",");
            let lung = imagesN.length;
            let i=0;
            for(i=0; i<imagesN.length; i++){
                tempImageSlides.push(this.newSlide(imagesN[i], i, lung, "Caption Text"));
                tempImageSlidesDots.push(this.newSlideDot(i, imagesN[i].startsWith("blob")));
            }
            if(this.state.editMode){
                tempImageSlides.push(this.uploadSlide(i));
                tempImageSlidesDots.push(this.uploadSlideDot(i));
            }
        }else{
            if(this.state.editMode){
                tempImageSlides.push(this.uploadSlide(0));
                tempImageSlidesDots.push(this.uploadSlideDot(0));
            }
        }
        this.setState({
            imageSlides: tempImageSlides,
            imageSlidesDots: tempImageSlidesDots
        });
    }

    render(){
        return(
            <div className={"ImageSliderImpianto-"+this.state.impiantoId}>
                <div className="imageSlider not-selectable">
                    { this.state.imageSlides.length>0 ? (
                        <span className="prev" onClick={()=>{this.prevnextImageSlide(-1)}}>&#10094;</span>
                        ) : ('')
                    }                                
                    { this.state.imageSlides }
                    { this.state.imageSlides.length>0 ? (
                        <span className="next" onClick={()=>{this.prevnextImageSlide(1)}}>&#10095;</span>
                        ) : ('')
                    }
                </div>
                <div className="imageSliderDots">
                    { this.state.imageSlidesDots  }
                </div>
            </div>
        );
    }

}