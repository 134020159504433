import { Component } from "react";
import { withRouter } from "react-router";
import ReactLoading from 'react-loading';
import md5 from 'md5';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';

import {isLogged} from "./LoginServices";
import {config, apiRoutes} from  "../../Utils/global";
import logo from "../../assets/images/logo.png"
import './Login.scss';

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            username: null,
            password: null,
            loginError: false,
            errorMessage: null
        }
    }

    handleChange(event) {
        const target = event.target;
        const valore = target.value;
        const proprieta = target.name;
        this.setState({
          [proprieta]: valore
        });
        if(this.state.loginError){
            this.setState({ loginError: false });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: this.state.username , password: md5(this.state.password) })
        };
        fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.messageType!=null && data.messageType === "response" && data.responseMessage === "ok"){

                    var redirectQ = (new URL(window.location.href)).searchParams.get("redirect");
                    if(redirectQ != null){
                        this.props.history.replace('/'+(redirectQ));
                    }else{
                        this.props.history.replace('/');
                    }
                    
                }else{
                    if(data.messageType === "error" && data.errorMessage!=null){
                        this.setState({ errorMessage: "Errore - "+data.errorMessage, loginError: true});
                    }else{
                        this.setState({ errorMessage: "Errore - Generico", loginError: true});
                    }
                    this.setState({ isLoading: false }); 
                }        
            });
    }

    componentDidMount(){
        if(isLogged()){
            this.props.history.replace('/');
            this.setState({ isLoading: false });
        }else{
            document.title = "Portaloutdoor - Login";
            this.setState({ isLoading: false });
        }
    }

    forgottenPassword = () => {
        Swal.fire(
            'Mi dispiace...',
            'Contatta l\'Amministratore...',
            'warning'
        );
    }

    render(){
        return(
            <div className="login-page">
                { this.state.isLoading ? (
                    <div className="layoutLoadingAnimation"> 
                        <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                    </div>
                ) : (
                    <div className="main">
                        <p className="sign" align="center"><img className="logo" src={logo} alt="portaloutdoor.net"/></p>
                        <form className="form1" method="post" onSubmit={(event)=>this.handleSubmit(event)}>
                            <input className="username" type="text" name="username" align="center" placeholder="Username/Email" value={this.state.user} onChange={(event)=>this.handleChange(event)} required autoFocus/>
                            <input className="pass" type="password" name="password" align="center" placeholder="Password" value={this.state.pw} onChange={(event)=>this.handleChange(event)} required/>
                            <Button type="submit" variant="contained" className="submit">
                                Sign in
                            </Button>
                        </form>
                        <p className="passLink" onClick={()=>this.forgottenPassword()}>Hai dimenticato la password?</p>
                        {this.state.loginError ? ( <Alert className="errorAlert" severity="error">{this.state.errorMessage}</Alert> ):("")}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Login);