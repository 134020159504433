import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';

import {config, GETnewEmptyImpiantoWithDefaults, Logout, apiRoutes} from  "../../Utils/global";
import "./DetailImpianto.scss";
import ImageSliderImpianto from "./CustomComponents/ImageSliderImpianto";

export default class DetailImpianto extends Component{

    constructor(props){
        super(props);

        let isNewImpianto = false;
        if(this.props.impianto === "*new"){
            isNewImpianto = true;
        }

        let saveButton = null;
        if(isNewImpianto){
            saveButton = <Button onClick={()=> this.handleClose('save')} style={{color:'green'}}>Salva</Button>;
        }

        this.impianto = this.props.impianto;
        this.closeDialogFunction = this.props.closeDialogFunction;
        this.originalImpianto = JSON.parse(JSON.stringify(this.props.impianto));
        this.images = this.props.impianto.images;

        let localImpianto = this.props.impianto;
        if(isNewImpianto){
            localImpianto = GETnewEmptyImpiantoWithDefaults();
        }

        this.state = {
            dialogOpen: true,
            editMode: isNewImpianto,
            editModeSaveButton: saveButton,
            impianto: localImpianto,
            currentSliderImgN: 1,
            imagesToDeleteOnServer: []
        }

        this.newSelectedImagesToUpload = [];
        this.dialogINNERLoading = React.createRef();
        this.imageSliderDetail = React.createRef();
    }

    checkIfImgToDeleteOnServer = () => {

        this.dialogINNERLoading.current.style.display = 'flex';

        if(this.state.imagesToDeleteOnServer != null && this.state.imagesToDeleteOnServer.length > 0){
            
            let imagesToDelete = this.state.imagesToDeleteOnServer.join(",");
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ deleteImg: imagesToDelete, impiantoId: this.impianto.id })
            };
            fetch(config.SERVER_ADDR+apiRoutes.manageImg, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                        this.checkIfImgToUploadOnServer(data.responseMessage.newImages);
                    }else{

                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }else{
                            if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                                Swal.fire(
                                    'Oh no...',
                                    data.errorMessage,
                                    'error'
                                );
                            }else{
                                Swal.fire(
                                    'Oh no...',
                                    'Qualcosa è andato storto',
                                    'error'
                                );
                            }
                            
                            console.log(data);
                            this.setState({
                                dialogOpen: false
                            }, () => {
                                this.closeDialogFunction(true);
                            });
                        }

                    }
                });

        }else{
            this.checkIfImgToUploadOnServer();
        }
    }

    checkIfImgToUploadOnServer = (newImagesAfterDeletion) => {
        let slider = document.getElementsByClassName("ImageSliderImpianto-"+this.impianto.id)[0];
        let sliderUploadForm = slider.getElementsByClassName("img-form-uploader")[0];
        let sliderUploadFormInput = sliderUploadForm.getElementsByClassName("form-uploader-input-file")[0];
        if(sliderUploadFormInput.files && sliderUploadFormInput.files.length > 0){
            const requestOptions = {
                method: 'POST',
                body: new FormData(sliderUploadForm)
            };
            fetch(config.SERVER_ADDR+apiRoutes.manageImg, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null && data.responseMessage.newImages !== null){

                        this.images = data.responseMessage.newImages;
                        this.impianto.images = this.images;
                        let newImpianto = this.state.impianto;
                        newImpianto.images = this.images;
                        this.setState({
                            impianto: newImpianto
                        }, () => {
                            this.saveNewDetailsFunction(true);
                        });

                    }else{

                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }else{
                            if(newImagesAfterDeletion!=null){
                                this.images = newImagesAfterDeletion;
                                this.impianto.images = this.images;
                                let newImpianto = this.state.impianto;
                                newImpianto.images = this.images;
                                this.setState({
                                    impianto: newImpianto
                                });
                            }else{
                                if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                                    Swal.fire(
                                        'Oh no...',
                                        data.errorMessage,
                                        'error'
                                    );
                                }else{
                                    Swal.fire(
                                        'Oh no...',
                                        'Qualcosa è andato storto',
                                        'error'
                                    );
                                }
                            }
                            
                            console.log(data);
                            this.setState({
                                dialogOpen: false
                            }, () => {
                                this.closeDialogFunction(true);
                            });
                        }
                        
                    }

                });

        }else{
            if(newImagesAfterDeletion!=null){
                this.images = newImagesAfterDeletion;
                this.impianto.images = this.images;
                let newImpianto = this.state.impianto;
                newImpianto.images = this.images;
                this.setState({
                    impianto: newImpianto
                }, () => {
                    this.saveNewDetailsFunction();
                });
            }else{
                this.saveNewDetailsFunction();
            }
        }
    }


    saveNewDetailsFunction = (forceOkMessage) => {
        if(JSON.stringify(this.originalImpianto) === JSON.stringify(this.state.impianto)){
            if(forceOkMessage){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Le modifiche sono state salvate',
                    showConfirmButton: false,
                    timer: 1000
                });
            }else{
                //Non è cambiato nulla, chiudo solo la finestra senza salvare inutilmente
                console.log('Tutto uguale... Non salvo nulla');
            }
            this.setState({
                dialogOpen: false
            }, () => {
                this.closeDialogFunction();
            });
        }else{

            console.log(this.state.impianto);

            let newInfosImpianto =
            '{'
                +'"idImpianto":"'+this.state.impianto.id+'"'
                +',"infos":['
                    +'{"name":"lat", "value":"'+this.state.impianto.lat+'"}'
                    +',{"name":"lng", "value":"'+this.state.impianto.lng+'"}'
                    +',{"name":"indirizzo", "value":"'+this.state.impianto.indirizzo+'"}'
                    //+',{"name":"idRegione", "value":"'+this.state.impianto.idRegione+'"}'
                    +',{"name":"idProvincia", "value":"'+this.state.impianto.idProvincia+'"}'
                    +',{"name":"idComune", "value":"'+this.state.impianto.idComune+'"}'
                    +',{"name":"idConcessionario", "value":"'+this.state.impianto.idConcessionario+'"}'
                    +',{"name":"cimasa", "value":"'+this.state.impianto.cimasa+'"}'
                    +',{"name":"idTipologia", "value":"'+this.state.impianto.idTipologia+'"}'
                    +',{"name":"idFormato", "value":"'+this.state.impianto.idFormato+'"}'
                    +',{"name":"idTipoIlluminazione", "value":"'+this.state.impianto.idTipoIlluminazione+'"}'
                    +',{"name":"idCategoria", "value":"'+this.state.impianto.idCategoria+'"}'
                +"]"
            +'}';

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ update: newInfosImpianto })
            };
            fetch(config.SERVER_ADDR+apiRoutes.details, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null){
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Le modifiche sono state salvate',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }else{

                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }else{
                            if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                                Swal.fire(
                                    'Oh no...',
                                    data.errorMessage,
                                    'error'
                                );
                            }else{
                                Swal.fire(
                                    'Oh no...',
                                    'Qualcosa è andato storto',
                                    'error'
                                );
                            }
                            
                            console.log(data);
                        }
                        
                    }
                    this.setState({
                        dialogOpen: false
                    }, () => {
                        this.closeDialogFunction(true);
                    });
                });

        }
    }


    createNewImpiantoOnServer = () => {

        this.dialogINNERLoading.current.style.display = 'flex';

        let newImpiantoInfos =
        '{'
            +'"idImpianto":"*new"'
            +',"infos":{'
                +'"lat":"'+this.state.impianto.lat+'",'
                +'"lng":"'+this.state.impianto.lng+'",'
                +'"indirizzo":"'+this.state.impianto.indirizzo+'",'
                +'"idProvincia":"'+this.state.impianto.idProvincia+'",'
                +'"idComune":"'+this.state.impianto.idComune+'",'
                +'"idConcessionario":"'+this.state.impianto.idConcessionario+'",'
                +'"cimasa":"'+this.state.impianto.cimasa+'",'
                +'"idTipologia":"'+this.state.impianto.idTipologia+'",'
                +'"idFormato":"'+this.state.impianto.idFormato+'",'
                +'"idTipoIlluminazione":"'+this.state.impianto.idTipoIlluminazione+'",'
                +'"idCategoria":"'+this.state.impianto.idCategoria+'"'
            +"}"
        +'}';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newImpianto: newImpiantoInfos })
        };
        fetch(config.SERVER_ADDR+apiRoutes.details, requestOptions)
            .then(response => response.json().then(data => ({status: response.status, body: data})))
            .then(obj => {
                let data = obj.body;
                if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null && data.responseMessage.newImpiantoId !== null){

                    this.imageSliderDetail.current.setImpiantoId(data.responseMessage.newImpiantoId);
                    this.checkImgToUploadNewImpianto(data.responseMessage.newImpiantoId);

                }else{

                    if(obj.status === 401){
                        Logout();
                        this.props.history.replace('/login');                                        
                    }else{
                        if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                            Swal.fire(
                                'Oh no...',
                                data.errorMessage,
                                'error'
                            );
                        }else{
                            Swal.fire(
                                'Oh no...',
                                'Qualcosa è andato storto',
                                'error'
                            );
                        }
                        
                        console.log(data);
                    }

                    this.setState({
                        dialogOpen: false
                    }, () => {
                        this.closeDialogFunction(true);
                    });
                    
                }
                
            });
    }

    checkImgToUploadNewImpianto = (newImpiantoId) => {

        //TODO - controllo se ci sono degli upload da fare e poi do la conferma e chiudo il dialog

        let slider = document.getElementsByClassName("ImageSliderImpianto-"+newImpiantoId)[0];
        let sliderUploadForm = slider.getElementsByClassName("img-form-uploader")[0];
        let sliderUploadFormInput = sliderUploadForm.getElementsByClassName("form-uploader-input-file")[0];
        if(sliderUploadFormInput.files && sliderUploadFormInput.files.length > 0){
            const requestOptions = {
                method: 'POST',
                body: new FormData(sliderUploadForm)
            };
            fetch(config.SERVER_ADDR+apiRoutes.manageImg, requestOptions)
                .then(response => response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    let data = obj.body;
                    if(data.messageType !== null && data.messageType === "response" && data.responseMessage !== null && data.responseMessage.newImages !== null){

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Impianto creato',
                            showConfirmButton: false,
                            timer: 1000
                        });
                
                        this.setState({
                            dialogOpen: false
                        }, () => {
                            this.closeDialogFunction(true);
                        });

                    }else{

                        if(obj.status === 401){
                            Logout();
                            this.props.history.replace('/login');                                        
                        }else{
                            if(data.messageType !== null && data.messageType === "error" && data.errorMessage !== null){
                                Swal.fire(
                                    'Oh no...',
                                    data.errorMessage,
                                    'error'
                                );
                            }else{
                                Swal.fire(
                                    'Oh no...',
                                    'Qualcosa è andato storto',
                                    'error'
                                );
                            }
                            
                            console.log(data);
                            this.setState({
                                dialogOpen: false
                            }, () => {
                                this.closeDialogFunction(true);
                            });
                            
                        }
                        
                    }

                });

        }else{
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Impianto creato',
                showConfirmButton: false,
                timer: 1000
            });
    
            this.setState({
                dialogOpen: false
            }, () => {
                this.closeDialogFunction(true);
            });
        }

    }


    handleClose = (action) => {
        switch(action){
            case 'save':
                //Before check if there's img to delete, after check if there's img to upload, after save all new infos
                if(this.impianto === "*new"){
                    this.createNewImpiantoOnServer();
                }else{
                    this.checkIfImgToDeleteOnServer();
                }
                break;
            case 'cancel':
                this.setState({
                    dialogOpen: false
                }, () => {
                    this.closeDialogFunction();
                });
                break;
            default:
                break;
        }
    }

    handleChangeInputImpianto = (event, stateName, decodeStateName) =>{
        this.setState(prevState => ({
            impianto:{
                ...prevState.impianto,
                [stateName]:event.target.value
            }
        }), function(){
            if(typeof decodeStateName !== undefined && decodeStateName!=null){
                let indexSelect = event.nativeEvent.target.selectedIndex;
                let valueSelect = event.nativeEvent.target[indexSelect].text;
                    
                this.setState(prevState => ({
                    impianto:{
                        ...prevState.impianto,
                        [decodeStateName]:valueSelect
                    }
                }));
            
            }
        });
    }

    handleEditModeChange = (event) => {
        if(event.target.checked === true){
            this.setState({
                editMode: event.target.checked,
                editModeSaveButton: <Button onClick={()=> this.handleClose('save')} style={{color:'green'}}>Salva</Button>
            });
        }else{
            this.setState({
                editMode: event.target.checked,
                editModeSaveButton: null
            });
        }
    }


    render(){
        return(
            <Dialog
                transitionDuration={10}
                open={this.state.dialogOpen}
                onClose={()=> this.handleClose('cancel')}
                scroll={'paper'}
                maxWidth={'md'}
                fullWidth={true}
                className="detailDialog"
            >

                <div className="loadingDialog" ref={this.dialogINNERLoading}>
                    <ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} />
                </div>

                <DialogTitle id="scroll-dialog-title">
                    {
                        (this.impianto.indirizzo) ? this.impianto.indirizzo : "Nuovo impianto"
                    }
                    {
                        (this.impianto !== "*new") ?
                        <FormControlLabel
                            className="editToggle"
                            control={<Switch checked={this.state.editMode} onChange={this.handleEditModeChange} color="primary" />}
                            label="Modifica"
                        />
                        :""
                    }
                </DialogTitle>
                    <DialogContent dividers={true} className="dialogContent">
                        <DialogContentText tabIndex={-1} component="div" style={{border: 'none', outline: 'none'}}>
                            {/*'ID: '+this.impianto.id*/}

                            <ImageSliderImpianto ref={this.imageSliderDetail} images={this.images} editMode={this.state.editMode} impiantoId={this.impianto.id} imagesToDeleteOnServer={this.state.imagesToDeleteOnServer}/>

                            <Divider className="divider"/>

                            <form>
                                <TextField disabled={!this.state.editMode} autoComplete='off' inputProps={{autoComplete: 'chrome-off'}} onChange={(e)=>{this.handleChangeInputImpianto(e, 'lat')}} className="inputs midSizeLeft" id="outlined-basic-lat" label="Lat" variant="outlined" server-input-type="text" server-id="lat" defaultValue={this.state.impianto.lat} />
                                <TextField disabled={!this.state.editMode} autoComplete='off' inputProps={{autoComplete: 'chrome-off'}} onChange={(e)=>{this.handleChangeInputImpianto(e, 'lng')}} className="inputs midSizeRight" id="outlined-basic-lng" label="Lng" variant="outlined" server-input-type="text" server-id="lng" defaultValue={this.state.impianto.lng} />
                                <Divider className="divider" />
                                <TextField disabled={!this.state.editMode} autoComplete='off' inputProps={{autoComplete: 'chrome-off'}} onChange={(e)=>{this.handleChangeInputImpianto(e, 'indirizzo')}} className="inputs" fullWidth id="outlined-basic-indirizzo" label="Indirizzo" server-input-type="text" server-id="indirizzo" variant="outlined" defaultValue={this.state.impianto.indirizzo} />
                                                                
                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs" fullWidth>
                                    <InputLabel htmlFor="outlined-regione-native-simple">Regione</InputLabel>
                                    <Select
                                        native
                                        label="Regione"
                                        inputProps={{
                                            name: 'regione',
                                            id: 'outlined-regione-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idRegione"
                                        value={this.state.impianto.idRegione}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idRegione', 'regione')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('regioni')).map(function(item, i){
                                                return <option key={'optionRegione-'+item.id} value={item.id}>{item.nome}</option>;                                         
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs midSizeLeft">
                                    <InputLabel htmlFor="outlined-provincia-native-simple">Provincia</InputLabel>
                                    <Select
                                        native
                                        label="Provincia"
                                        inputProps={{
                                            name: 'provincia',
                                            id: 'outlined-provincia-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idProvincia"
                                        value={this.state.impianto.idProvincia}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idProvincia', 'provincia')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('province')).map(function(item, i){
                                                return <option key={'optionProvincia-'+item.id} value={item.id}>{item.nome}</option>;                                                
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs midSizeRight">
                                    <InputLabel htmlFor="outlined-comune-native-simple">Comune</InputLabel>
                                    <Select
                                        native
                                        label="Comune"
                                        inputProps={{
                                            name: 'comune',
                                            id: 'outlined-comune-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idComune"
                                        value={this.state.impianto.idComune}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idComune', 'comune')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('comuni')).map(function(item, i){
                                                return <option key={'optionComune-'+item.id} value={item.id}>{item.nome}</option>;                                               
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <Divider className="divider" />

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs midSizeLeft">
                                    <InputLabel htmlFor="outlined-concessionario-native-simple">Concessionario</InputLabel>
                                    <Select
                                        native
                                        label="Concessionario"
                                        inputProps={{
                                            name: 'concessionario',
                                            id: 'outlined-concessionario-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idConcessionario"
                                        value={this.state.impianto.idConcessionario}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idConcessionario', 'concessionario')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('concessionari')).map(function(item, i){
                                                return <option key={'optionConcessionario-'+item.id} value={item.id}>{item.nome}</option>;                                                
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <TextField disabled={!this.state.editMode} autoComplete='off' inputProps={{autoComplete: 'chrome-off'}} onChange={(e)=>{this.handleChangeInputImpianto(e, 'cimasa')}} className="inputs midSizeRight" id="outlined-basic-cimasa" label="Cimasa" server-input-type="text" server-id="cimasa" variant="outlined" defaultValue={this.state.impianto.cimasa} />
                                
                                <Divider className="divider" />
                                
                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs midSizeLeft">
                                    <InputLabel htmlFor="outlined-tipologia-native-simple">Tipologia</InputLabel>
                                    <Select
                                        native
                                        label="Tipologia"
                                        inputProps={{
                                            name: 'tipologia',
                                            id: 'outlined-tipologia-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idTipologia"
                                        value={this.state.impianto.idTipologia}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idTipologia', 'tipologia')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('tipologie')).map(function(item, i){
                                                return <option key={'optionTipologia-'+item.id} value={item.id}>{item.nome}</option>;                                               
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs midSizeRight">
                                    <InputLabel htmlFor="outlined-formato-native-simple">Formato</InputLabel>
                                    <Select
                                        native
                                        label="Formato"
                                        inputProps={{
                                            name: 'formato',
                                            id: 'outlined-formato-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idFormato"
                                        value={this.state.impianto.idFormato}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idFormato', 'formato')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('formati')).map(function(item, i){
                                                return <option key={'optionFormato-'+item.id} value={item.id}>{item.nome}</option>;                                               
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs" fullWidth>
                                    <InputLabel htmlFor="outlined-tipoilluminazione-native-simple">Tipo Illuminazione</InputLabel>
                                    <Select
                                        native
                                        label="Tipo Illuminazione"
                                        inputProps={{
                                            name: 'tipoilluminazione',
                                            id: 'outlined-tipoilluminazione-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idTipoIlluminazione"
                                        value={this.state.impianto.idTipoIlluminazione}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idTipoIlluminazione', 'tipoilluminazione')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('tipiilluminazione')).map(function(item, i){
                                                return <option key={'optionTipoilluminazione-'+item.id} value={item.id}>{item.nome}</option>;                                               
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>

                                <Divider className="divider" />

                                <FormControl disabled={!this.state.editMode} variant="outlined" className="inputs" fullWidth>
                                    <InputLabel htmlFor="outlined-categoria-native-simple">Categoria</InputLabel>
                                    <Select
                                        native
                                        label="Categoria"
                                        inputProps={{
                                            name: 'categoria',
                                            id: 'outlined-categoria-native-simple',
                                        }}
                                        server-input-type="select"
                                        server-id="idCategoria"
                                        value={this.state.impianto.idCategoria}
                                        onChange={(e)=>{this.handleChangeInputImpianto(e, 'idCategoria', 'categoria')}}
                                        >
                                        <option value=""></option>
                                        {
                                            JSON.parse(localStorage.getItem('categorie')).map(function(item, i){
                                                return <option key={'optionCategoria-'+item.id} value={item.id}>{item.nome}</option>;                                                
                                            }, this)
                                        }
                                    </Select>
                                </FormControl>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                <Button onClick={()=> this.handleClose('cancel')} color="primary">
                    Annulla
                </Button>
                { this.state.editModeSaveButton }
                </DialogActions>
            </Dialog>
        )
    }
}