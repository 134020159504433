import { Component } from "react";
import { withRouter } from "react-router";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CardActions from '@material-ui/core/CardActions';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import EditIcon from '@material-ui/icons/Edit';

import {config, apiRoutes, Logout} from  "../../../../Utils/global";
import "./MapsSubpage.scss";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class Maps extends Component{

    constructor(props){
        super(props);
        this.favoritesMaps = this.props.favoritesMaps;
        this.state = {
            messageSnack: null,
            messageSnackOpen: false
        }
    }

    handleShareClick = (url) => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = document.location.origin + url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.setState({messageSnackOpen: true, messageSnack: "Url copiato negli appunti"});
    }

    handleChangeInput = (event, stateName) =>{
        this.setState({
            [stateName]:event.target.value
        })
    }

    handleSnackClose = () => {
        this.setState({messageSnackOpen: false, messageSnack: null});
    }

    handleDeleteClick = (idFavMap, favMapTitle) => {
        Swal.fire({
            title: 'Vuoi davvero eliminare la mappa?',
            showCancelButton: true,
            confirmButtonText: "Elimina",
            confirmButtonColor: "#ea5455",
            cancelButtonText: "Annulla"
        }).then((result) => {
            if (result.isConfirmed) {

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        extFunction: "deleteFavoriteMap", 
                        title: favMapTitle, 
                        favoriteid: idFavMap
                    })
                };
                fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                    .then(response => response.json().then(data => ({status: response.status, body: data})))
                    .then(obj => {
                        let data = obj.body;
                        if(data.messageType !== null && data.messageType === "response"){
                            window.location.reload();
                        }else{

                            if(obj.status === 401){
                                Logout();
                                this.props.history.replace('/login');                                        
                            }else{
                                console.error(data);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Eliminazione preferito non riuscita'
                                });
                            }
                            
                        }                          

                    });

            }
        });
    }

    handleModifyClick = (idFavMap, favMapTitle, favMapDescription) => {
        Swal.fire({
            title: 'Modifica preferito',
            focusConfirm: true,
            html:
            '<div class="modifyFavSwal">'+
                '<label class="introLabel">Salva la ricerca nei tuoi preferiti per poterla riaprire velocemente un\'altra volta!</label>'+
                '<div class="modifyFavSwal-input"><label>Nome</label><input autocomplete="off" id="swal-input1-name-modified" value="'+favMapTitle+'" class="swal2-input"></div>' +
                '<div class="modifyFavSwal-input"><label>Descrizione</label><input autocomplete="off" id="swal-input2-descr-modified" value="'+favMapDescription+'" class="swal2-input"></div>'+
            '</div>',
            showCancelButton: true,
            confirmButtonColor: config.themeColor,
            preConfirm: () => {
                let nameFavModified = document.getElementById('swal-input1-name-modified').value.trim();
                let descrFavModified = document.getElementById('swal-input2-descr-modified').value.trim();
                let currentFavId = idFavMap;
                if(nameFavModified !== "" && descrFavModified !== "" && currentFavId !== ""){
                    
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            extFunction: "modifyFavoriteMap",
                            newTitle: nameFavModified,
                            newDescription: descrFavModified,
                            favoriteid: currentFavId
                        })
                    };
                    fetch(config.SERVER_ADDR+apiRoutes.auth, requestOptions)
                        .then(response => response.json().then(data => ({status: response.status, body: data})))
                        .then(obj => {
                            let data = obj.body;
                            if(data.messageType !== null && data.messageType === "response"){
                                window.location.reload();
                            }else{

                                if(obj.status === 401){
                                    Logout();
                                    this.props.history.replace('/login');                                        
                                }else{
                                    console.error(data);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Qualcosa è andato storto'
                                    });
                                }
                                
                            }                         

                        });

                }else{
                    Swal.fire('Inserisci entrami i valori: Titolo e Descrizione');
                }
            }
        });
    }

    render(){
        return(
            <div className="maps-subpage">
                <div className="mapsList">
                    <div className="title">Mappe</div>
                    <div className="list">
                        <div className="cards toolsCard">
                            <Button className="tools-item" variant="contained" href="/map">
                                <SearchIcon fontSize="large"/>
                            </Button>
                            {/*<Button className="tools-item" variant="contained" href="/map">
                                <HomeIcon fontSize="large"/>
                            </Button>*/}
                            {/*<Button className="tools-item" variant="contained">
                                b2
                            </Button>
                            <Button className="tools-item" variant="contained">
                                b3
                            </Button>
                            <Button className="tools-item" variant="contained">
                                b4
                            </Button>*/}
                        </div>
                        {
                            this.favoritesMaps.map(function(item, i){
                                let componentScope = this;
                                return(
                                    
                                        <Card className={"cards"} key={"favoriteMap-"+item.id}>
                                            
                                            <CardActionArea>
                                                <Link to={"/map?"+item.url} style={{textDecoration:'none', color:'black'}}>
                                                    <CardMedia
                                                    component="span"
                                                    style={{height:'50px',backgroundColor:config.themeColor}}
                                                    alt="Contemplative Reptile"
                                                    title="Contemplative Reptile"
                                                    />
                                                    <CardContent style={{height:'114px'}}>
                                                        <Typography gutterBottom variant="h5" component="h2" className="titleCard">
                                                            {item.titolo}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p" className="descrizioneCard">
                                                            {item.descrizione}
                                                        </Typography>
                                                    </CardContent>
                                                </Link>
                                            </CardActionArea>
                                            <CardActions className="actions">
                                                <IconButton size="small" onClick={()=>componentScope.handleModifyClick(item.id, item.titolo, item.descrizione)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton size="small" onClick={()=>{componentScope.handleShareClick("/map?"+item.url)}}>
                                                    <ShareIcon />
                                                </IconButton>
                                                <IconButton size="small" onClick={()=>componentScope.handleDeleteClick(item.id, item.titolo)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </CardActions>

                                            <Snackbar open={componentScope.state.messageSnackOpen} autoHideDuration={3000} onClose={componentScope.handleSnackClose}>
                                                <Alert severity={"info"} onClose={componentScope.handleSnackClose}>
                                                    {componentScope.state.messageSnack}
                                                </Alert>
                                            </Snackbar>

                                        </Card>
                                    
                                )
                            },this)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Maps);