import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { GoogleMap, LoadScript, Marker, OverlayView, MarkerClusterer } from '@react-google-maps/api';
import IconButton from '@material-ui/core/IconButton';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import ReactLoading from 'react-loading';

import { mapStyle } from "./MapStyle";
import {config} from  "../../../Utils/global";
import { ControlPosition } from "./MapConstants"; 
import "./Map.scss";
import defaultMarkerIcon from "../../../assets/icons/defaultMarker.svg";
import selectedMarkerIcon from "../../../assets/icons/selectedMarker-orange.svg";

//iconClusterMap
import markerGroupIcon1 from "../../../assets/icons/markerClusterIcons/markerGroup1.png";
import markerGroupIcon2 from "../../../assets/icons/markerClusterIcons/markerGroup2.png";
import markerGroupIcon3 from "../../../assets/icons/markerClusterIcons/markerGroup3.png";
import markerGroupIcon4 from "../../../assets/icons/markerClusterIcons/markerGroup4.png";
import markerGroupIcon5 from "../../../assets/icons/markerClusterIcons/markerGroup5.png";

const optionsCluster = {styles: [
    {
    height: 53,
    url: markerGroupIcon1,
    width: 53,
    textColor: '#ffffff'
    },
    {
    height: 56,
    url: markerGroupIcon2,
    width: 56,
    textColor: '#ffffff'
    },
    {
    height: 66,
    url: markerGroupIcon3,
    width: 66,
    textColor: '#ffffff'
    },
    {
    height: 78,
    url: markerGroupIcon4,
    width: 78,
    textColor: '#ffffff'
    },
    {
    height: 90,
    url: markerGroupIcon5,
    width: 90,
    textColor: '#ffffff'
    }
]};

const containerStyle = {
    width: '100%',
    height: '100%'
};

const originalCenter = {
    lat: 42.800710,
    lng: 12.723340
};

const mapOptions = {
    styles: mapStyle,
    fullscreenControl: false,
    streetViewControl: true,
    streetViewControlOptions: {
        position: ControlPosition.RIGHT_CENTER
    },
    zoomControlOptions: {
        position: ControlPosition.RIGHT_CENTER
    },
    mapTypeControlOptions: {
        position: ControlPosition.RIGHT_TOP
    }
}

class Map extends Component {

    constructor(props){
        super(props);
        this.googleMap = null;
        this.mapBounds = null;
        this.onClickMarker = this.props.onClickMarker;
        this.onMapReady = this.props.onMapReady;
        this.markerToFocusFromDetail = this.props.markerToFocusFromDetail;
        this.state = {
            mapLoaded: false,
            markers: this.props.posizioni,
            loading: this.props.loading
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.loading !== this.props.loading){
            this.setState({
                loading: this.props.loading
            });
        }
        if(prevProps.posizioni !== this.props.posizioni){
            this.setState({
                markers: this.props.posizioni
            },()=>{
                if(this.state.markers.length != null && this.state.markers.length > 0){
                    this.mapBounds = new window.google.maps.LatLngBounds();
                    for(let i=0;i<this.state.markers.length;i++){
                        this.mapBounds.extend(new window.google.maps.LatLng(parseFloat(this.state.markers[i].lat), parseFloat(this.state.markers[i].lng)));
                    }
                    this.googleMap.fitBounds(this.mapBounds);
                }else{
                    this.googleMap.panTo(new window.google.maps.LatLng(originalCenter.lat, originalCenter.lng));
                    this.googleMap.setZoom(6);
                }
            });
            
        }
    }

    focusThisMarker = (idToFocus) =>{
        //tolgo il vecchio marker selezionato
        var markerWithSelectedIcon = this.state.markers.map(function(item){
            if(item.selected && item.selected === true){
                delete item.selected;
                return item;
            }else{
                return item;
            }
        });
        var index = markerWithSelectedIcon.findIndex(function(item, i){
            return item.id === idToFocus;
        });
        markerWithSelectedIcon[index].selected = true;
        //metto il nuovo marker selezionato
        this.setState({markers: markerWithSelectedIcon});

        this.googleMap.panTo(new window.google.maps.LatLng(parseFloat(this.state.markers[index].lat), parseFloat(this.state.markers[index].lng)));
        this.googleMap.setZoom(16);
    }

    handleMapLoad = (map) =>{
        if(window.google !== undefined){
            this.googleMap = map;
            this.setState({mapLoaded: true});

            const centerMapOnMarkersButtonDiv = document.createElement('div');
            ReactDOM.render(<IconButton className={"centerMapButton"} onClick={() => {this.handleControlsClick('riposiziona');}} ><CenterFocusStrongIcon/></IconButton>, centerMapOnMarkersButtonDiv);
            map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(centerMapOnMarkersButtonDiv);

            this.mapBounds = new window.google.maps.LatLngBounds();
            
            for(let i=0;i<this.state.markers.length;i++){
                this.mapBounds.extend(new window.google.maps.LatLng(parseFloat(this.state.markers[i].lat), parseFloat(this.state.markers[i].lng)));
            }

            map.fitBounds(this.mapBounds);

            this.onMapReady();

        }else{
            console.error("Errore nel caricamento della mappa...");
        }        
    }

    handleMarkerClick = (marker) =>{
        console.log("Cliccato marker ID: "+marker.id+", val: "+marker.indirizzo);
        this.onClickMarker(marker.id);
        this.focusThisMarker(marker.id);
    }

    handleControlsClick = (action) =>{
        switch(action){
            case 'riposiziona':
                //Tolgo il marker diverso di quello selezionato e ricentro la mappa
                if(this.state.markers.length != null && this.state.markers.length > 0){
                    var originalMarkers = this.state.markers.map(function(item){
                        if(item.selected && item.selected === true){
                            delete item.selected;
                            return item;
                        }else{
                            return item;
                        }
                    });
                    this.setState({markers: originalMarkers},()=>{
                        this.googleMap.fitBounds(this.mapBounds);
                        this.onClickMarker(null);
                    });
                }else{
                    this.googleMap.panTo(new window.google.maps.LatLng(originalCenter.lat, originalCenter.lng));
                    this.googleMap.setZoom(6);
                }                
                break;
            default:
                break;
        }
    }

    componentDidMount(){

    }

    render() {

        let loadingAnimation = '';
        if(this.state.loading === true){
            loadingAnimation = <div className="resultsLoading"><ReactLoading type={config.defualtLoadingAnimation} color={config.themeColor} /></div>;
        }

        return (
            <LoadScript googleMapsApiKey={config.googleMapKey}>

                {loadingAnimation}

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    options={mapOptions}
                    center={originalCenter}
                    zoom={10}
                    onLoad={map => this.handleMapLoad(map)}
                >

                
                <OverlayView
                    position={originalCenter}
                    mapPaneName={OverlayView.MARKER_LAYER}
                >

                    <MarkerClusterer options={optionsCluster}>
                        {(clusterer) =>{
                            let markerSelectedIcon= new window.google.maps.MarkerImage(
                                selectedMarkerIcon,
                                null, /* size is determined at runtime */
                                null, /* origin is 0,0 */
                                null, /* anchor is bottom center of the scaled image */
                                new window.google.maps.Size(32, 32)
                            );
                            let markerDefaultIcon= new window.google.maps.MarkerImage(
                                defaultMarkerIcon,
                                null, /* size is determined at runtime */
                                null, /* origin is 0,0 */
                                null, /* anchor is bottom center of the scaled image */
                                new window.google.maps.Size(32, 32)
                            );
                            return this.state.markers.map(function(position){
                                if(position.selected === true){
                                    return <Marker title={"markerSelected"} icon={markerSelectedIcon} key={"markerId-"+position.id} position={{"lat":parseFloat(position.lat), "lng": parseFloat(position.lng)}} clusterer={clusterer} onClick={()=>{this.handleMarkerClick(position)}} />
                                }else{
                                    return <Marker title={position.name} icon={markerDefaultIcon} key={"markerId-"+position.id} position={{"lat":parseFloat(position.lat), "lng": parseFloat(position.lng)}} clusterer={clusterer} onClick={()=>{this.handleMarkerClick(position)}} />
                                }
                            }, this)
                        }}
                    </MarkerClusterer>

                </OverlayView>
                

                </GoogleMap>
            </LoadScript>
        )
    }
}

export default(Map);