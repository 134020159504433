import { createTheme } from '@material-ui/core/styles';

export const config = {
    SERVER_ADDR: "",
    themeColor: "#513AB2",
    defualtLoadingAnimation: "bars",
    defaultTitlePage: "Portaloutdoor",
    impiantiImagesPath: "/impianti/images",
    /*AIzaSyA93AZHZL6Bjmbrc-CLJPhm2EK8ayAmM_M*/
    googleMapKey: "AIzaSyA93AZHZL6Bjmbrc-CLJPhm2EK8ayAmM_M"
}

export const apiRoutes = {
    auth: "/api/auth",
    search: "/api/search",
    details: "/api/details",
    manageImpianti: "/api/details/manageImpianti",
    manageImg: "/api/details/manageImg"
}

export const apiCookies = {
    apiToken: "t_user"
}

//THEME FOR MATERIAL UI LIBRARY
export const AppTheme = createTheme({
    palette: {
        primary: {
            main: config.themeColor,
        }
    },
});

export var GETnewEmptyImpiantoWithDefaults = function() {

    var getCategoriaDefaultValue = () => {
        let LOCAL_items = JSON.parse(localStorage.getItem('categorie'));
        for(let i=0; i<LOCAL_items.length; i++){
            if(LOCAL_items[i].nome === "Visibile"){
                return LOCAL_items[i].id;
            }
        }
    }

    var getTipologiaDefaultValue = () => {
        let LOCAL_items = JSON.parse(localStorage.getItem('tipologie'));
        for(let i=0; i<LOCAL_items.length; i++){
            if(LOCAL_items[i].nome === "Medio Formato"){
                return LOCAL_items[i].id;
            }
        }
    }
    
    return ({
        categoria: "Visibile",
        cimasa: "",
        comune: "",
        concessionario: "",
        formato: "",
        id: "new",
        idCategoria: getCategoriaDefaultValue(),
        idComune: "",
        idConcessionario: "",
        idFormato: "",
        idProvincia: "",
        idRegione: "",
        idTipoIlluminazione: "",
        idTipologia: getTipologiaDefaultValue(),
        images: null,
        indirizzo: "",
        lat: "",
        lng: "",
        provincia: "",
        regione: "",
        siglaprovincia: "",
        tipoilluminazione: "",
        tipologia: "Medio Formato"
    });
    
};

export function Logout(){
    localStorage.clear();
}